import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaChevronDown, FaTag } from 'react-icons/fa';
import { IoIosArrowDown, IoMdArrowRoundBack } from 'react-icons/io';
import './membershipview.css';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useSelector } from 'react-redux';
import { IoPerson } from 'react-icons/io5';
import DatePicker from "react-datepicker";
import { convertDateFormat } from '../customfunction/convertformatdate';
import { default as Select } from 'react-select';
import { useParams } from 'react-router-dom';
import { decryptData } from '../cryptoutils/cryptoutils';

const Serviceadmin = () => {
  const{userid,memberid}=useParams()
  const userId=decryptData(userid)
  const memberId=decryptData(memberid)
  const [isFormVisible, setisvisible] = useState(true);
  const [servicelist, setservicelist] = useState([]);
  const [therapistname, settherapistname] = useState([]);
  const [servicehourdata, setservicehour] = useState([]);
  const[loading,setloading]=useState(false)
  const selector = useSelector(state => state);
  const managername = selector.details.data && selector.details.data[""][0].manager_name;
  const[show,setshow]=useState(true)
  const[showcreate,setcreate]=useState(true)
  const[showedit,setedit]=useState(false)
  const [membershipdop,setmembershipdop]=useState("")
  const[expire,setexpire]=useState("")
  const[submitting,setSubmitting]=useState(false)
  const[validity,setvalidity]=useState("")
  const[editdata,seteditdata]=useState([])
  const[servicedetailiddata,setservicedetail_id]=useState(null)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(userid,"userid")
  let user_id =userid

  const [data, setdata] = useState({
    therapistnamee: "",
    treatmenthr: "",
    servicedate: "",
    timein: "",
    timeout: "",
    roomno: "",
    therapy_start_time:"",
    therapy_end_time:""
  });
  const handleclear=()=>{

    setdata(prevData => {
      console.log("Previous Data:", prevData);
      const newData = {
        ...prevData,
      treatmenthr: "",
      therapistnamee:"",
      servicedate: "",
      timein: "",
      timeout: "",
      roomno: "",
      therapy_start_time:"",
      therapy_end_time:""
      };
      console.log("New Data:", newData);
      return newData;
    });

  }

  // update query

 const updatequery=async(e)=>{
  e.preventDefault()

  console.log(data)
  const timedin = convertTimeToAMPMFormat(data.timein);
const timedout=convertTimeToAMPMFormat(data.timeout);
const formattedDate = data.servicedate;
 const obj={
  query:`[dbo].[spasoftware_web_procservice_details_edit_query]@serviceid='{0}',@memberid='{1}',@editDetailsid='{2}',@therapist_name='{3}',@treatment_hour='{4}',@room_no='{5}',	@service_date='{6}' ,@time_in='{7}',@time_out='{8}'`,
  queryArr:[`${userId}`,`${memberId}`,`${servicedetailiddata}`,`${data.therapistnamee}`,`${data.treatmenthr}`,`${data.roomno}`,`${formattedDate}`, `${timedin}`, `${timedout}`]
 }
 const getpost=await Hexapi(obj)
 getservicelist();
 console.log(getpost)
 window.scrollTo(0,0)
 }

 const handleDateChange = (date, name) => {
  if(date){
    setdata({ ...data, [name]: date.toISOString().split('T')[0] });
  }else{
    setdata({ ...data, [name]: "" });
  }
};

const setCurrentDateIfEmpty = (name) => {
  if (!data[name]) {
    setdata({ ...data, [name]: new Date().toISOString().split('T')[0] });
  }
};



const therapistOptions = therapistname.map(therapist => ({
  value: therapist.therapist_name,
  label: therapist.therapist_name
}));

therapistOptions.push({
  label:managername,
  value:managername
})

const handleTherapistChange = (selectedOption) => {
  setdata({ ...data, therapistnamee: selectedOption ? selectedOption.value : '' });
};

const treactmentoption=servicehourdata.map(hr=>({
  value:hr.servicehour,
  label:hr.service_hour
}))
const handletreatmentChange = (selectedOption) => {
  setdata({ ...data, treatmenthr: selectedOption ? selectedOption.value : '' });
};




// Function to convert time from "HH:mm" format to AM/PM format
const convertTimeToAMPMFormat = (time) => {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minutes} ${ampm}`;
};

// Function to convert time from AM/PM format to "HH:mm" format
function formatTime(input) {
  let timeParts = input.split(" ");
  let time = timeParts[0].split(":");
  let hours = parseInt(time[0], 10);
  let minutes = time[1];
  let period = timeParts[1].toUpperCase();

  if (period === "PM" && hours < 12) {
      hours += 12;
  } else if (period === "AM" && hours === 12) {
      hours = 0;
  }

  let formattedHours = hours < 10 ? '0' + hours : hours;
  return `${formattedHours}:${minutes}`;
}

let inputTime = "5:23 AM";
let formattedTime = formatTime(inputTime);
console.log(formattedTime);  // Output: 05:23


const handleedit = (servicedetailid) => {
  setservicedetail_id(servicedetailid);
  setedit(true);
  setcreate(false);

  const serviceData = servicelist.find(service => service.service_details_id === servicedetailid);

  if (serviceData) {
    console.log(serviceData.treatment_hr,"---------")
    const timeIn =serviceData.time_in;
    const timeOut = serviceData.time_out;

    setdata((prev) => ({
      ...prev,
      therapistnamee: serviceData.therapist_name,
      treatmenthr: serviceData.treatment_hr,
      servicedate: serviceData.service_date.date.split(' ')[0],
      timein: formatTime(timeIn),
      timeout: formatTime(timeOut),
      roomno: serviceData.room_no,
      therapy_start_time: serviceData.therapy_start_time,
      therapy_end_time: serviceData.therapy_end_time
    }));
  }
};



//  getdata query
  const getservicelist = async () => {
    const obj = {
      query: `[dbo].[spasoftware_web_proc_new_service_details_admin_getdata]@serviceid='{0}',@memberid='{1}'`,
      queryArr: [`${userId}`,`${memberId}`]
    };
    const getdata = await Hexapi(obj);
    console.log(getdata,"membershiopdop")
    setservicelist(getdata.servicedatatable|| []);
    settherapistname(getdata.therapistname || []);
    setservicehour(getdata.servicehour || []);
    setmembershipdop((getdata[""] && getdata[""][0] && getdata[""][0].membership_dop.date.split(' ')[0] )  || "" )
    setexpire((getdata[""] && getdata[""][0] && getdata[""][0].membership_expire.date.split(' ')[0] )  || "")
    setvalidity((getdata[""] && getdata[""][0] && getdata[""][0].membership_validity_id  ) || "")
    seteditdata(getdata.servicedatatable)
  };

  useEffect(() => {
    getservicelist();
  }, [memberId,userId]);



  const handleservicelist = (e) => {
    const {name, value} = e.target;
    setdata({...data, [name]: value});
    console.log(data);
  };



  const handleservicelistsubmit = async (e) => {
    e.preventDefault();
    if (submitting) return; // Prevent multiple submissions

    setSubmitting(true);
    const formattedDate = convertDateFormat(data.servicedate);
    console.log(formattedDate, "formattedDate");  // Debug log
    const timeZone = 'Asia/Kolkata';
    function formatTime(date) {
    return date.toISOString().substr(11, 8);
}

const currentDate = new Date().toLocaleString('en-US', { timeZone });
const therapyStartTime = new Date(currentDate);
console.log(therapyStartTime,"tagehguh")
// console.log('Therapy Start Time:', formatTime(therapyStartTime));
const treatmentHour = data.treatmenthr
const currentTime = new Date(currentDate);
const endTime = new Date(currentTime.getTime() + treatmentHour * 60 * 60 * 1000);


const timedin = convertTimeToAMPMFormat(data.timein);
const timedout=convertTimeToAMPMFormat(data.timeout);
    const obj = {
      query: `[dbo].[spasoftware_web_proc_new_service_userdetails_admin_insert]@userid='{0}',@manager_name='{1}',@therapistname='{2}',@treatmenthr='{3}',@roomno='{4}',@servicedate='{5}',@timein='{6}',@timeout='{7}',@therapystart='{8}',@therapyend='{9}'`,
      queryArr: [`${userId}`, `${managername}`, `${data.therapistnamee}`, `${data.treatmenthr}`, `${data.roomno}`, `${formattedDate}`, `${timedin}`, `${timedout}`,`${formatTime(therapyStartTime)}`,`${formatTime(endTime)}`]
    };

    const getpost = await Hexapi(obj);
    console.log(getpost)
    setSubmitting(false)
    getservicelist()
  };
  return (
    <div>
    { loading ? <div className="text-center mt-5"><l-line-spinner
     size="40"
     stroke="3"
     speed="1"
     color="black"
     className="relative"

   ></l-line-spinner></div> : (<div className='container'>
    <div className='row'>
    <div className='bg-body-tertiary'>

          <div className='dashservice'>
          <p className='name-text'><FaTag/>SPA Master List</p>
          <p><span className=' text-danger'>{membershipdop  && membershipdop != '' ?
            <span> Membership Date : - {membershipdop} &nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp;
             Membership Expire Date : - {expire} &nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp; Membership Validity_id : - &nbsp;&nbsp;{validity
}
</span>: null} &nbsp;&nbsp;&nbsp;&nbsp;</span><FaChevronDown  onClick={()=>{setshow(!show)}}/></p>

          </div>
         {show &&

         <div><Table className="mt-3 mb-3 bor-dis" responsive striped>
            <thead>
              <tr>
                <th><IoPerson/> Member Id</th>
                <th> <FaTag/> Time In</th>
                <th> <FaTag/> Time Out</th>
                <th> <IoPerson/> Therapist Name</th>
                <th> <FaTag/> Treatment Hour</th>
                <th> <FaTag/> Room No</th>
                <th> <IoPerson/> Create By</th>
                <th> <FaTag/> Service Date</th>
              </tr>
            </thead>
            <tbody>
              {servicelist.length ? servicelist.map((ele, index) => (
                <tr key={index}>
                 { selector.details.data !=null && selector.details.data[""][0].user_role !=='SALES' ? <td>{ele.service_status === 1 ? <span className='active'></span> : <span className='inactive'></span>}&nbsp;&nbsp; <span onClick={()=>handleedit(ele.service_details_id)} className='curpoint'> {ele.member_id}</span></td> : null}
                 { selector.details.data !=null && selector.details.data[""][0].user_role ==='SALES' ? <td>{ele.service_status === 1 ? <span className='active'></span> : <span className='inactive'></span>}&nbsp;&nbsp; <span> {ele.member_id}</span></td> : null}
                  <td>{ele.time_in}</td>
                  <td>{ele.time_out}</td>
                  <td>{ele.therapist_name}</td>
                  <td>{ele.treatment_hr}</td>
                  <td>{ele.room_no}</td>
                  <td>{ele.create_by}</td>
                  <td>{new Date(ele.service_date.date).toLocaleDateString()} &nbsp;&nbsp;&nbsp;&nbsp; {ele.service_status === 1 ? <span className='btactive'>Active</span> : <span className='btactive-1'>Inactive</span>} {ele.service_status===1 ? (selector.details.data!=null && selector.details.data[""][0].user_role!='SALES' ? <button className='btn-click-4'>cancel</button>:null):null}</td>
                </tr>
              )) : <tr><td className=' text-center fs-6' colSpan={17}>No Data Available </td></tr>}
            </tbody>
          </Table></div>}
        </div>

      { showcreate && ( selector.details.data != null ? (selector.details.data.user_role!=='SALES' ? (<>{ user_id !=="" ? (<div className=" mt-4">
          <div className="create_therapist_heading">
            <div>
              <span className="create-heading4">SERVICE DETAILS</span>

            </div>


                <IoIosArrowDown onClick={() => { setisvisible(!isFormVisible) }} style={{marginRight:"5px"}}/>


          </div>
          {isFormVisible && (
            <form className="form-horizontal mb-4" id="ename" >
              <div className="control-group">
                <label className="control-label">Therapist Name</label>
                <div className="controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapistnamee"
                    options={therapistOptions}
                    value={therapistOptions.find(option => option.value === data.therapistnamee)}
                    onChange={handleTherapistChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Therapist"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Treatment Hour</label>
                <div className="controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="total_service_hour"
                    options={treactmentoption}
                    value={treactmentoption.find(option => option.value === data.treatmenthr)}
                    onChange={handletreatmentChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Treatment hour"
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Room Number</label>
                <div className="therapist_controls">
                  <input
                    type="text"
                    className="span6 popovers"
                    name="roomno"
                    id="therapist_name"
                    required
                    value={data.roomno}
                    onChange={handleservicelist}
                  />
                  <br />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Service Date</label>
                <div className="controls">
                <DatePicker
                  selected={data.servicedate}
                  onChange={(date) => handleDateChange(date, 'servicedate')}
                  className="span6 popovers"
                  dateFormat="dd/MM/yyyy"
                  onFocus={() => setCurrentDateIfEmpty('servicedate')}
                />

                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Time In</label>
                <div className="controls">
                  <input
                    className="span6"
                    size="16"
                    type="time"
                    name="timein"
                    required
                    onChange={handleservicelist}
                    value={data.timein}
                  />
                  <span className="add-on">
                    <i className="icon-calendar"></i>
                  </span>
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Time Out</label>
                <div className="controls">
                  <input
                    type="time"
                    className="span6 popovers"
                    name="timeout"
                    id="biometic_id"
                    required
                    value={data.timeout}
                    onChange={handleservicelist}
                  />
                </div>
              </div>
              <div className="form-actions">
                <button
                  type="submit"
                  id="create-btn"
                  className="btn btn-success"
                  onClick={handleservicelistsubmit}
                  disabled={submitting}
                >
                {submitting ? 'Submitting...' : 'Create'}
                </button>
                <button type="reset" className="btn-cancel btn" onClick={handleclear}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>) : null}


      </>): null): null)}
      { showedit && ( selector.details.data != null ? (selector.details.data.user_role!=='SALES' ? (<>{ user_id !== "" && servicedetailiddata !=="" ? (<div className=" mt-4">
          <div className="create_therapist_heading">
            <div>
              <span className="create-heading4">
                 {/* <button onClick={()=>{setcreate(true)}}>back to create</button> */}

                 EDIT SERVICE DETAILS</span>

            </div>
                <IoIosArrowDown onClick={() => { setisvisible(!isFormVisible) }} style={{marginRight:"5px"}}/>
          </div>
          {isFormVisible && (
            <form className="form-horizontal mb-4" id="ename" >
              <div className="control-group">
                <label className="control-label">Therapist Name</label>
                <div className="controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapistnamee"
                    options={therapistOptions}
                    value={therapistOptions.find(option => option.value === data.therapistnamee)}
                    onChange={handleTherapistChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Therapist"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Treatment Hour</label>
                <div className="controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="treatmenthr"
                    options={treactmentoption}
                    value={treactmentoption.find(option => option.value === data.treatmenthr)}
                    onChange={handletreatmentChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Treatment hour"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Room Number</label>
                <div className="therapist_controls">
                  <input
                    type="text"
                    className="span6 popovers"
                    name="roomno"
                    id="therapist_name"
                    required
                    value={data.roomno}
                    onChange={handleservicelist}
                  />
                  <br />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Service Date</label>
                <div className="controls">
                <DatePicker
                  selected={data.servicedate}
                  onChange={(date) => handleDateChange(date, 'servicedate')}
                  className="span6 popovers"
                  dateFormat="dd/MM/yyyy"
                  onFocus={() => setCurrentDateIfEmpty('servicedate')}
                />
                  <span className="add-on">
                    <i className="icon-calendar"></i>
                  </span>
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Time In</label>
                <div className="controls">
                  <input
                    className="span6"
                    size="16"
                    type="time"
                    name="timein"
                    required
                    onChange={handleservicelist}
                    value={data.timein}
                  />

                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Time Out</label>
                <div className="controls">
                  <input
                    type="time"
                    className="span6 popovers"
                    name="timeout"
                    id="biometic_id"
                    required
                    value={data.timeout}
                    onChange={handleservicelist}
                  />
                </div>
              </div>
              <div className="form-actions">
                <button
                  type="submit"
                  id="create-btn"
                  className="btn btn-success"
                  onClick={updatequery}
                >
                  Update
                </button>
                <button type="reset" className="btn-cancel btn" onClick={handleclear}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>) : null}
      </>): null): null)}
    </div>
  </div>)}
   </div>
  );
}

export default Serviceadmin
