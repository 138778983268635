import CryptoJS from 'crypto-js';

const secretKey = 'blog-hex-secretkey';

const toBase64UrlSafe = (base64) => {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const fromBase64UrlSafe = (base64UrlSafe) => {
  let base64 = base64UrlSafe.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return base64;
};

export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    const encryptedUrlSafe = toBase64UrlSafe(encrypted);
    return encryptedUrlSafe;
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};

export const decryptData = (encryptedUrlSafe) => {
  try {
    const encrypted = fromBase64UrlSafe(encryptedUrlSafe);
    const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    if (!decryptedData) {
      throw new Error('Decryption resulted in invalid data.');
    }

    return decryptedData;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};
