import React, { useEffect, useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useSelector } from 'react-redux';

import { Aesdecryption, Aesencryption } from '../usefulcomps/Aes/Aes';
import './membershipview.css';
import { FaChevronDown } from 'react-icons/fa';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs';
import { Spinner } from 'react-bootstrap';
import { encryptData } from '../cryptoutils/cryptoutils';
import { Link } from 'react-router-dom';
lineSpinner.register();




const MembershipAccount = () => {
  const [branchtable,setBranchTable] = useState('')
  const [report, setData] = useState([]);
  const selector = useSelector(state => state);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const[loading,setLoading]=useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchMembershipData = async () => {
      setLoading(true)
      if (selector.details.data && selector.details.data[""]) {
        const branchName = selector.details.data[""][0].spa_branch_name;
        setBranchTable(selector.details.data[""][0].branchtable);
        const obj = {
          query: `[dbo].[spasoftware__web_proc_view_membership_user_getdata]@branch_name='{0}'`,
          queryArr: [`${branchName}`],
        }

        const getData = await Hexapi(obj);
        setData(getData.service_master_details || []);
        setLoading(false)

      }
    };

    fetchMembershipData();
  }, [selector]);


  function maskContact(contact) {
    if (contact.length < 4) {
      return contact;
    }
    const lastFourDigits = contact.slice(-4);
    const maskedPart = '*'.repeat(contact.length - 4);
    return maskedPart + lastFourDigits;
  }

  function formatDate(dateString) {
    let date = new Date(dateString);
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const date = new Date();
  const year = date. getFullYear();
  const month = String(date. getMonth() + 1). padStart(2, '0');
  const day = String(date. getDate()). padStart(2 ,'0');
  const formattedDate = `${year}-${month}-${day}`;



  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{formatDate(row.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
    { Header: "Branch Name", accessor: "branch_name" },
    {
      Header: "Member Id",
      accessor: "member_id",
    },
    { Header: "Customer Name", accessor: "cust_name" },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor:(row)=>(<span className='text-danger'>{row.cust_bal_amount}</span>) },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Create By", accessor: "create_by" },
    {
      Header: "View Service",
      accessor: "booking",
      Cell: ({ row }) => {
        return(
<>
<Link to={`/viewservice/${encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">service</Link>
        { row.original.cust_bal_service===0 ? <Link className="btn-click-2" to={`/renewservice/${ encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} rel="noopener noreferrer" target="_blank">Renew</ Link>: null}
       {row.original.cust_dop===formattedDate ?
        (<>
           <Link to={`/viewinvoice/${encryptData(row.original.user_id)}/${selector.details.data ? encryptData(selector.details.data[""][0].branchtable) : null}`} className='btn-click-2' rel="noopener noreferrer" target="_blank">view invoice</Link>
          </>)
        : null}
</>
        )
      }





    },
    {
      Header: "Tip Account",
      accessor: "tip",
      Cell: ({ row }) => (
        <Link to={`/tipaccount/${encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} rel="noopener noreferrer" target="_blank" className='btn-click-1'>Tip</Link>
      )
    },
    {
      Header: "Pending Amount",
      accessor: "pending",
      Cell: ({ row }) => (
        <Link to={`/paidaccount/${encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`}  rel="noopener noreferrer" target="_blank" className='btn-click-1'>Paid</Link>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);

  return (
    <>
        <div className='container mt-1 mb-1'>
          <div className='row bg-body-tertiary'>
            <div className='dashservice'>
              <p className='name-text'>Membership Account</p>
              <FaChevronDown />
            </div>
            <div>
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 25, 50, 75, 100].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={globalFilter || ""}
                      onChange={e => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {loading ? (
                        <tr>
                       <td colSpan={17} className="text-center">
                       <Spinner animation="border" />
                     </td>
                        </tr>
                      ) : report.length === 0 ? (
                        <tr>
                          <td className="fs-6" colSpan={17}>No Data Available</td>
                        </tr>
                      ) : (
                        page.map(row => {
                          prepareRow(row);
                          return (
                            <tr  key={row.id} {...row.getRowProps()}>
                              {row.cells.map(cell => (
                                <td key={cell.column.id} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              ))}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                  <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                    </div>
                  </div>





                </div>
          </div>
        </div>

    </>
  );
};

export default MembershipAccount;
