import React from 'react'
import { useSelector } from 'react-redux';
import './Navbar.css'

const Footer = () => {
  const selector = useSelector(state => state);
  const createBy = selector.details.data && selector.details.data[""][0].create_by
  return (
    <div className=' d-flex justify-content-center align-items-center mt-4 '>
      <p className=' text-white text-center'>Software Developed By {createBy}: 9717020671
        103.174.102.78</p>
    </div>
  )
}

export default Footer