import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown, FaTag } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import { useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';
import { IoPerson } from "react-icons/io5";
import Tipadmin from "./Tipadmin";
import { lineSpinner } from 'ldrs';
import { IoMdArrowRoundBack } from "react-icons/io";
import { default as Select } from 'react-select';
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { decryptData, encryptData } from "../cryptoutils/cryptoutils";
lineSpinner.register();
export const Tipaccountadmin = () => {
  const{userid,memberid}=useParams()
  const userId=decryptData(userid)
  const memberId=decryptData(memberid)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selector = useSelector(state => state)
  const user_id=userid
  const [show, setshow] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [showtable, setshowtable] = useState(true);
  const [payment, setpayment] = useState([]);
  const[data,setData]=useState([])
  const[loading,setloading]=useState(false)
  const [therapistName, setTherapistName] = useState([]);
  const[branchname,setbranchname]=useState([])
  const[submitting,setSubmitting]=useState(false)
  const [formValues, setFormValues] = useState({
    paymentmode: "",
    therapist_name: '',
    total_tip_amount: '',
    branchnamedata:"",
    receivedate:"",
    adddate:"",
    tip_amount_80:'',
    tip_amount_20:'',
  });





const handlecancel=()=>{
  setFormValues({
    paymentmode: "",
    therapist_name: '',
    total_tip_amount: '',
    branchnamedata:"",
    receivedate:"",
    adddate:"",
    tip_amount_80:'',
    tip_amount_20:'',
  })
}
  const handlepaid_amount_change = (e) => {
    console.log(e.target.value)
    const paidData = e.target.value;
    if (!isNaN(paidData)) {
      const paidValue = parseFloat(paidData);

      setFormValues((prev)=>({
        ...prev,
        total_tip_amount:paidValue,
        tip_amount_80:paidValue*0.7 || 0,
        tip_amount_20:paidValue*0.3 || 0
      }))

    } else {
      console.log('Invalid input: Please enter a valid number.');
    }
  };
  const querydata=async()=>{
    let obj = {
      query: `[dbo].[spasofware_web_proc_tip_account_admin_getdata]@memberId='{0}'`,
      queryArr: [`${memberId}`]
    }
   const getdata=await  Hexapi(obj)
      console.log(getdata[""]);
      setData(getdata[""]);
      setpayment(getdata.paymentmode   || []);
      setTherapistName(getdata.therapistname || []);
      setbranchname(getdata.branch || [])
      setDataAvailable(true)
  }
  useEffect(() => {
    querydata()
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault()
    console.log(formValues,"values")
 if(selector.details.data !=null){
   try {
    const managername=selector.details.data && selector.details.data[""][0].manager_name
    const obj = {
        query : `[dbo].[spasofware_web_proc_tip_Account_Admin_query]@serviceid='{0}',@member_id='{1}',@payment_mode='{2}',@therapist_name='{3}',@total_tip_amount='{4}',@eighty_tip_amount='{5}',@twenty_tip_amount='{6}',@branch_name='{7}',@m_name ='{8}',@receive_date='{9}',@add_date='{10}'`,
        queryArr:[`${userId}`,`${memberId}`,`${formValues.paymentmode}`,`${formValues.therapist_name}`,`${formValues.total_tip_amount}`,`${formValues.tip_amount_80}`,`${formValues.tip_amount_20}`,`${formValues.branchnamedata}`,`${managername}`,`${formValues.receivedate}`,`${formValues.adddate}`]
      }
     const postdata= await Hexapi(obj)
        console.log(postdata,"post")
                setFormValues({
                    paymentmode: "",
                    therapist_name: "",
                    total_tip_amount:"",
                    branchnamedata:"",
                    receivedate:"",
                    adddate:"",
                    tip_amount_80:'',
                    tip_amount_20:'',
                })
                querydata()
                window.scrollTo(0, 0);
                toast.success(postdata[""][0]['msg'])

   } catch (error) {
      window.scrollTo(0, 0);
      toast.error("Tip failed")
   }
 }
  }




    const therapistOptions = therapistName.map(therapist => ({
      value: therapist.therapist_name,
      label: therapist.therapist_name
    }));

    const handleTherapistChange = (selectedOption) => {
      setFormValues({ ...formValues, therapist_name: selectedOption ? selectedOption.value : '' });
    };

    const handlebranchChange = (selectedOption) => {
      setFormValues({  ...formValues,  branchnamedata: selectedOption ? selectedOption.value : '' });
    };
    const branchOptions = branchname.map(br => ({
      value: br.branchname,
      label: br.branchname
    }))

    const paymentOptions = payment.map(th => ({
      value: th.paymentmode,
      label: th.paymentmode
    }));
      const handlepaymentChange = (selectedOption) => {
        setFormValues({ ...formValues, paymentmode: selectedOption ? selectedOption.value : '' });
      };


      const handleChangeReport = (date, name) => {
        setFormValues({ ...formValues, [name]: date.toISOString().split('T')[0] });
      };
      const setCurrentDateIfEmpty = (name) => {
        if (!data[name]) {
          setFormValues({ ...formValues, [name]: new Date().toISOString().split('T')[0] });
        }
      };
  return (
    <div>
      <div className="container top-acc highlightBcg">
      <div className="row">
        <div className="dashservice">
          <p className="name-text">
          Tip Accounts
          </p>

            <FaChevronDown onClick={() => { setshow(!show) }} className="hideShowBtn" />

        </div>
      </div>

      <div className="row bg-body-tertiary
      ">
        {show && (
          <div className="form-data" >
            <div className="controls">
            <div className="control-group">
                <label className="control-label">
                  Select Branch Name
                </label>
                <div className="blinkTextDiv controls">
                <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="branch"
                      options={branchOptions}
                      value={branchOptions.find(option => option.value === formValues.branchnamedata)}
                      onChange={handlebranchChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Branch Name"
                    />

                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                Receive Tip Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={formValues.receivedate}
                    onChange={(date) => handleChangeReport(date, 'receivedate')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('receivedate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">

                Add Tip Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={formValues.adddate}
                    onChange={(date) => handleChangeReport(date, 'adddate')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('adddate')}
                  />

                </div>
              </div>

              <div className="control-group">
                <label className="control-label">
                  Select Therapist Name
                </label>
                <div className="blinkTextDiv controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapist_name"
                    options={therapistOptions}
                    value={therapistOptions.find(option => option.value === formValues.therapist_name)}
                    onChange={handleTherapistChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Therapist"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Select Payment Mode
                </label>
                <div className="blinkTextDiv controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapistnamee"
                    options={paymentOptions}
                    value={paymentOptions.find(option => option.value ===formValues.paymentmode)}
                    onChange={handlepaymentChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Payment Mode"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Total Tip Amount
                </label>
                <div className="controls">
                  <input
                    type="text"
                    className="span6  popovers"
                    name="customer_name"
                    id="customer_name"
                    required

onChange={handlepaid_amount_change}
                  />
                </div>
              </div>


              <div className="control-group">
                <label className="control-label">
                  70% Tip Amount
                </label>
                <div className="controls">
                  <input
                    className="span7  popovers"
                    type="text"
 value={formValues.tip_amount_80}
                    name="customer_no"
                    id="customer_no"
                    required

                    disabled

                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  30% Tip Amount
                </label>
                <div className="controls">
                  <input
                    className="span7  popovers"
                    type="text"
                    disabled
                    value={formValues.tip_amount_20}
                    name="customer_no"
                    id="customer_no"

                  />
                </div>
              </div>
              <div className="createcancel_btn">
                <button
                  // type="submit"
                  className="btn-data"
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                {submitting ? "submitting" : "Paid"}
                </button>
                <button type="reset" className="cancel-btn btn" onClick={handlecancel}>Cancel</button>
              </div>
            </div>
          </div>)}
      </div>
      <div className="row bg-body-tertiary mt-4">
<div className="dashservice">
  <p className="name-text">Tip Amount Details</p>
  <FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
 <div>
 {showtable && ( <Table responsive striped className=" table table-striped  table-bordered mt-3 mb-3 bor-dis">
    <thead className="">
      <tr>
      <th><IoPerson /><span >Member Id</span></th>
                                            <th> <IoPerson /><span >Branch Name</span></th>
                                            <th><FaTag/><span >Payment Type</span></th>
                                            <th><FaTag/><span >Service Date</span></th>
                                            <th><IoPerson /><span >Therapist Name</span></th>
                                            <th><FaTag/><span >Total Tip Amount</span></th>
                                            <th><FaTag/><span >70% Tip Amount</span></th>
                                            <th><FaTag/><span >30% Tip Amount</span></th>
                                            <th><IoPerson /><span >Operater By</span></th>

      </tr>
    </thead>

    <tbody>
     {data && data.map((ele)=>{
      return(
        <tr>
        <td className="curpoint">{ele.tip_status === 1 ? <span className='active'></span> : <span className='inactive'></span>}<Link to={`/tipaccountAdminUpdate/${ encodeURIComponent(encryptData(ele.user_id))}/${ encodeURIComponent(encryptData(ele.member_id))}/${ encodeURIComponent(encryptData(ele.tip_master_id))}`} className="curpoint" >{ele.member_id}</Link></td>
        <td>{ele.branch_name}</td>

        <td>{ele.payment_mode}</td>
        <td>{ele.service_date.date.split(' ')[0]}</td>
        <td>{ele.therapist_name}</td>
        <td>{ele.total_tip_amount}</td>
       <td>{ele.tip_amount_80}</td>
<td>{ele.tip_amount_20}</td>
        <td>{ele.tip_paid_by}
        <br/>{ele.tip_status===1 ? <p className="text-info">Confirm</p> : <p className="text-danger">Cancel</p>}</td>

        </tr>
      )
     })}
    </tbody>
  </Table>)}
 </div>
      </div>
    </div>
   </div>
  );
}