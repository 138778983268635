import React, {useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { Spinner, Table } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronDown } from "react-icons/fa";
import './attendance.css'
import { Form } from 'react-bootstrap';
import { default as Select } from 'react-select';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs'
import { maskContact } from "../customfunction/dateformatmonth";
import { encryptData } from "../cryptoutils/cryptoutils";
import { Link, useNavigate } from "react-router-dom";
lineSpinner.register()


export default function AllmembershipAccount() {
  const navigate=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const[visible,setvisi]=useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isFormVisible, setFormVisible] = useState(true);
  const[data,setdata]=useState({
    searchoption:"",
    enter_details:""
  })

const[report,setquicksearch]=useState([])
const selector= useSelector(state=>state)
const[loading,setloading]=useState(false)

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const handlechange=(e)=>{
 const{name,value}=e.target
 setdata({...data,[name]:value})
  }
 const handleappointemnet=()=>{
 navigate('appointment')
 }
  const handlesubmit=async(e)=>{
    e.preventDefault()
    setloading(true)
    if(selector.details.data){
      const branchname=selector.details.data[""][0].spa_branch_name
      const obj={
        query:`[dbo].[spasoftware_web_proc_advancesearch_getdata_query]@branchname='{0}',@searchoption='{1}',@enterdetails='{2}'`,
        queryArr:[`${branchname}`,`${data.searchoption}`,`${data.enter_details}`]
      }
     try {
      const getdata=await Hexapi(obj)
      console.log(getdata.quickadvancesearch)
      setquicksearch(getdata.quickadvancesearch)
      setloading(false)
     } catch (error) {
            console.log(error)
     }
    }
    setloading(false)
  }

function formatDate(dateString) {
  // Create a new Date object from the input date string
  let date = new Date(dateString);

  // Define an array of month names
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract the day, month, and year from the Date object
  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();

  // Return the formatted date string
  return `${day}-${month}-${year}`;
}

const handleSearchOptionChange = (selectedOption) => {
  setdata({ ...data, searchoption: selectedOption ? selectedOption.value : '' });
};


const searchOptions = [
  { value: "cust_contact", label: "Customer Number" },
  { value: "member_id", label: "Customer Member Id" },
];


const columns = useMemo(() => [
  { Header: "Sr No", accessor: (_, i) => i + 1 },
  { Header: "DOJ", accessor: "cust_dop" , Cell:({row})=>(<div className="text-info">{formatDate(row.original.cust_dop)}<span className="text-danger"> actual date <br/> {row.original.actual_date}</span> </div>)},
  { Header: "Member Id",

  accessor:"member_id",

  Cell: ({row})=>(<>{row.original.member_id} <br/>
<p className="blink">{row.original.cust_type}</p></>)
 },

 {
  Header: "Customer Name",
  accessor: "cust_name",
  Cell: ({ row }) => (
    <>
      {row.original.cust_name}<br />
      {row.original.cust_paid_amount < 10000 ? (<p className="blink">Not Valid in all Branch</p>) : null}
    </>
  )
},
  { Header: "Customer Number", accessor: "cust_contact" , Cell:({row}) => maskContact(row.original.cust_contact) },
  { Header: "Payment Mode", accessor: "payment_mode" },
  { Header: "Total Amount", accessor: "cust_total_amount" },
  { Header: "Paid Amount", accessor: "cust_paid_amount" },
  { Header: "Pending Amount", accessor:"cust_bal_amount",Cell:({row})=>(<p className="text-danger">{row.original.cust_bal_amount}</p>) },
  { Header: "Total Service", accessor: "cust_total_service" },
  { Header: "Used Service", accessor: "cust_used_service" },
  { Header: "Balance Service", accessor: "cust_bal_service" },
  { Header: "Created By", accessor: "create_by" },
  {
    Header: "View Service",
    accessor: "booking",
    Cell: ({ row }) => (
      <>

<Link to={`/viewservice/${ encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank"> {row.original.cust_paid_amount < 10000 ? 'View history' : 'Service'}</Link>
<Link className="btn-click-2" to={`/renewservice/${ encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} rel="noopener noreferrer" target="_blank">Renew</ Link>
          {row.original.cust_used_service===0 ? <button className="btn-click-4">cancel</button> : null}
      </>
    )
  },
  {
    Header: "Tip Account",
    accessor: "tip",
    Cell: ({ row }) => (
      <>
     <Link to={`/tipaccount/${ encryptData(row.original.user_id)}/${ encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Tip</Link>
      </>
    )
  },
  {
    Header: "Pending Account",
    accessor: "pending",
    Cell: ({ row }) => (
      <>

<Link to={`/paidaccount/${ encryptData(row.original.user_id)}/${ encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Paid</Link>
        </>
    )
  },
  {
    Header: "Book Appointment",
    accessor: "bookappointment",
    Cell: ({ row }) => (
      <>
        <Link to='/appointment' className="btn-click-1" rel="noopener noreferrer" target="_blank">Appointment</Link>
        </>
    )
  }
], []);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  setPageSize,
  state: { pageIndex, pageSize, globalFilter },
  setGlobalFilter,
} = useTable(
  {
    columns,
    data: report,
    initialState: { pageIndex: 0, pageSize: itemsPerPage },
  },
  useGlobalFilter,
  useSortBy,
  usePagination
);

const totalEntries = useMemo(() => report.length, [report]);
const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
   <div className='container mb-5'>
  <div className='row topatt'>
 <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>ADVANCE SEARCH</h4></p>
  <FaChevronDown className='text-new' onClick={()=>{setvisi(!visible)}}/>
 </div>
{visible && (<>
  <div>
  <form onSubmit={handlesubmit}>
   <div className='form-data mt-3'>
   <Form.Label>
     Select Search Option
     </Form.Label>
    <Select

                      id="therapist_weekly_off"
                      name="searchoption"
                      options={searchOptions}
                      value={searchOptions.find(option => option.value === data.searchoption)}
                      onChange={handleSearchOptionChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Search Option"
                    />
    </div>
   <div className='form-data mt-3'>
    <Form.Label>
    Enter Details
    </Form.Label>
    <input
    name="enter_details"
      value={data.enter_details}
      onChange={handlechange}
      className="span8"
     />

    </div>
   <hr/>
    <div className='newshow'>
      <button className='btn-1' >Show</button>
      <button className='cancel-btn'>Cancel</button>
    </div>
    <hr/>
  </form>
</div>

<marquee className="marqueetag">
                <h3>
                  <b>
                    If customer's Contact Number does not match or customer
                    forgets the Contact Number, in this case the Manager should
                    Contact the concerned Branch Manager for verification.
                  </b>
                </h3>
              </marquee></>)}
  </div>


         <div className="row">
          <div className="disapp">
      <div class="createNewBtn widget-body">
        <p>
          <button className="new_btn btn"> New Customer</button>
          <a>
            <button class="btn btn-warning" id="create_appoint_btn" onClick={()=>handleappointemnet()}>
              <FaPlus />
              Create Appointment
            </button>
          </a>
        </p>
      </div>
      </div>
      </div>

        <div className=" row bg-body-tertiary">
        <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>Membership Account</h4></p>
  <FaChevronDown className='text-new' onClick={toggleFormVisibility}/>
 </div>

  <div className=" px-2">
          { isFormVisible && (

<div className="mb-3">
<div className="d-flex justify-content-between mt-2">

 <select
    value={pageSize}

    onChange={(e) => {
      setItemsPerPage(Number(e.target.value));
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 20, 30, 40, 50,75,100].map(pageSize => (
      <option key={pageSize} value={pageSize}>
        Show {pageSize}
      </option>
    ))}
  </select>
  <input
  value={globalFilter || ""}
  onChange={(e) => setGlobalFilter(e.target.value)}
  className=" mb-3"
  placeholder="search..."
/>
  </div>
<Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render("Header")}
            <span>
              {column.isSorted
                ? column.isSortedDesc
                  ? " 🔽"
                  : " 🔼"
                : ""}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {loading ? (
      <tr>
      <td colSpan={17} className="text-center">
      <Spinner animation="border" />
    </td>
       </tr>
    ) : report.length === 0 ? (
      <tr>
        <td className="fs-6" colSpan={17}>No Data Available</td>
      </tr>
    ) : (
      page.map(row => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            ))}
          </tr>
        );
      })
    )}
  </tbody>
</Table>
<div className="d-flex justify-content-between mb-3">
<span>
Showing {startIndex}-{endIndex} of {totalEntries} entries
{remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
<div className="pagination">
  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
    Previous
  </button>
  <span>
    Page{' '}
    <strong>
      {pageIndex + 1} of {pageOptions.length}
    </strong>{' '}
  </span>
  <button onClick={() => nextPage()} disabled={!canNextPage}>
    Next
  </button>

</div>
</div>
</div>

        )}
        </div>
        </div>
        </div>


    </>
  );
}