import React from 'react'
import '../pages/page.css'
import { useSelector } from 'react-redux'

const Highlight = () => {
  const selector=useSelector((state)=>state)
  return (
    <div className="container-fluid">
        <div className="row py-4">

                <marquee>
                    <h3 className=' text-dan'>Delhi NCR Membership Plans: - 10,000 =&gt; 7/8 Services || 15,000 =&gt; 11/12 Services || 20,000 =&gt; 17/18 Services || 25,000 =&gt; 22/25 Services || 50,000 =&gt; 50/55 Services || 1,00,000 =&gt; 110/115 Services</h3>
                </marquee>
                <div className='blink-ani'>
                <h3 className=' mt-2'>Alert Message: All Managers, Please Update Therapist Details &amp; Upload Id Proof.</h3>
                <h3 className=' mt-3' >{selector.details.data && selector.details.data[""][0].spa_branch_name } Operator Name :: <a className=' text-dan'> Kulpreet Singh (Sunny Makker)</a></h3>
            </div>
            </div>
    </div>
  )
}

export default Highlight