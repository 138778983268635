import React, { useEffect, useRef, useState } from 'react';
import noimg from "../img/img/noimg.png";
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import PropTypes from 'prop-types';
import './addtherapist.css';

function AddTherapistDoc({ viewfunc, onDocUpdate }) {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const [selectedImage, setSelectedImage] = useState(noimg);
    const [fileData, setFileData] = useState(null);
    const fileInputRef = useRef(null);
    const [isFormVisible, setFormVisible] = useState(true);
    const [imagePath, setImagePath] = useState(noimg);

    useEffect(() => {
        console.log(viewfunc)
        if (viewfunc) {
            setImagePath(viewfunc);
            // setSelectedImage(viewfunc);
        }
    }, [viewfunc]);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const toggleFormVisibility = () => {
        setFormVisible(!isFormVisible);
    };

    const handleRemoveImage = () => {
        setSelectedImage(noimg);
        setFileData(null);
        fileInputRef.current.value = null;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setFileData(file);
        }
    };

    const handleUploadClick = () => {
        if (fileData) {
            const newImageUrl = URL.createObjectURL(fileData);
            setImagePath(newImageUrl);
            onDocUpdate(newImageUrl);

        }
    };

    const handleImageError = () => {
        console.log('Failed to load image:', selectedImage);
        setImagePath(noimg);
    };
    const handleClick = () => {
        window.location.href = '/addtherapist';
        // window.history.back();
    };
    return (
        <>
            <div className="back-button" onClick={handleClick}>
                <FaArrowLeft className="back-icon" />
                <span className="back-text">Back</span>
            </div>
            <div className='image-container'>
                <img src={viewfunc} className="selected_img" alt="Selected document" onError={handleImageError} />
            </div>
            <div className="add_therepist">
                <div className="create_therapist_heading">
                    <h4 className="create-heading4">
                        Upload ID Proof File
                    </h4>
                    <div className="head-btn">
                        <button id="arrow-button" onClick={toggleFormVisibility}>
                            <IoIosArrowDown />
                        </button>
                    </div>
                </div>
                {isFormVisible && (
                    <>
                        <div className="control-group" style={{ backgroundColor: "white", width: '100%', marginLeft: '0', padding: '5px' }}>
                            <label className="control-label">ID Proof Upload</label>
                            <div className="controlsFile">
                                <div className="fileupload fileupload-new" data-provides="fileupload">
                                    <div className="fileupload-new thumbnail" style={{ width: "200px", height: "150px", marginLeft: "2rem" }}>
                                        <img src={selectedImage} alt="" className="selected_img" />
                                    </div>
                                    <div className="fileupload-preview fileupload-exists thumbnail" style={{ maxWidth: "200px", maxHeight: "150px", lineHeight: "20px" }}></div>
                                    <div>
                                        {selectedImage === noimg ? (
                                            <button onClick={handleButtonClick} className="select_btn">Select image</button>
                                        ) : (
                                            <>
                                                <button type="button" onClick={handleButtonClick} className="select_btn">Change</button>
                                                <button type="button" onClick={handleRemoveImage} className="select_btn_remove">Remove</button>
                                            </>
                                        )}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                <button className="label-important">NOTE!</button>
                                <span className="noteLabel">Upload Id Proof, Click on Select Image Button</span>
                            </div>
                        </div>
                        <div className="control-group" style={{ backgroundColor: "white", width: '100%', marginLeft: '0', paddingBottom: '15px', display: 'flex', justifyContent: 'center', borderTop: '1px solid lightgrey' }}>
                            <button type="button" style={{ backgroundColor: 'green' }} onClick={handleUploadClick} className="select_btn">Upload</button>
                            <button type="button" onClick={handleRemoveImage} className="select_btn_remove">Cancel</button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

AddTherapistDoc.propTypes = {
    viewfunc: PropTypes.string,
    onDocUpdate: PropTypes.func.isRequired,
};

export default AddTherapistDoc;