import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import { useSelector } from "react-redux";
import { default as Select } from 'react-select';
import { useNavigate } from "react-router-dom";
import { encryptData } from "../cryptoutils/cryptoutils";

const Createservice = () => {
  const navigate=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleclear = () => {
    setdata({
      customer_name: "",
      customer_address: "",
      customer_no: "",
      customer_signature: "",
      customer_service: "",
      reference_name: "",
      payment_mode: "",
      basic_amount: "",
      tax_amount: "",
      total_bill_amount: "",
      paid_amount: "",
      security_code: "",
      amount_in_word: "",
      balance_account: "",
      discount_account: "",
      basic_amount_after_discount: "",
      after_discount_tax: "",
      total_amount: "",
      total_service_hour: "",
      branchname: "",
      spaname: "",
      createby: ""
    })
  }

  const selector = useSelector(state => state);
  const [branch, setbranch] = useState(null)

  const [data, setdata] = useState({
    dop_date: "",
    customer_name: "",
    customer_address: "",
    customer_no: "",
    customer_signature: "",
    customer_service: "",
    reference_name: "",
    payment_mode: "",
    basic_amount: "",
    tax_amount: "",
    total_bill_amount: "",
    paid_amount: "",
    amount_in_word: "",
    balance_account: "",
    discount_account: "",
    basic_amount_after_discount: "",
    after_discount_tax: "",
    total_amount: "",
    total_service_hour: "",
    branchname: "",
    spaname: "",
    createby: ""
  })

  const [show, setshow] = useState(true)
  const [paydata, setpaydata] = useState([])
  const [formdataget, setformdataget] = useState([])
  const [servicehourdata, setservicehour] = useState([]);
  const[submitting,setSubmitting]=useState(false)


  const selectformgetdata = async () => {
    if (selector.details.data !== null) {
      const branchname = selector.details.data[""][0].spa_branch_name
      const objdata = {
        query: `[dbo].[spasoftware_web_proc_createaccount_formgetdata]@branchname='{0}'`,
        queryArr: [`${branchname}`]
      }
      // console.log(objdata,"objdata")
      try {
        const obj = await Hexapi(objdata)
        console.log(obj.refname
          , "objget")
        setformdataget(obj.refname)
        setservicehour(obj.servicehour)
      } catch (error) {
        console.log("error")
      }
    }

  }
  const handlechangedate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    setdata((prev) => ({
      ...prev,
      dop_date: formattedDate,
    }));
  };

  const handlebasic_amount = (e) => {
    const basicamount = e.target.value
    const basic = parseInt(basicamount)
    const taxdata = parseInt((basic * 18) / 100)
    const totalbillamunt = basic + taxdata
    setdata((prev) => ({
      ...prev,
      basic_amount: basic,
      tax_amount: taxdata,
      total_bill_amount: totalbillamunt
    })
    )
  }
  const handlepaid_amount_change = (e) => {
    const paiddata = e.target.value;

    if (!isNaN(paiddata)) {
      const paidvalue = parseInt(paiddata);
      const wordsamount = convertNumberToWords(paidvalue);
      const balanceamount = data.total_bill_amount - paidvalue

      setdata((prev) => ({
        ...prev,
        paid_amount: paidvalue,
        amount_in_word: wordsamount,
        balance_account: balanceamount
      }));
    } else {
      console.log('Invalid input: Please enter a valid number.');
    }

    if (isNaN(paiddata)) {
      setdata((prev) => ({
        ...prev,
        paid_amount: "",
        amount_in_word: "",
        balance_account: ""
      }));
      return;
    }
  };
  function convertNumberToWords(amount) {
    if (isNaN(amount) || amount === 0) {
      return '';
    }
    let words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    let atemp = amount.split(".");
    let number = atemp[0].split(",").join("");
    let n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }




  const handlediscount = (e) => {
    const discountdata = e.target.value
    const disdata = parseInt(discountdata)
    console.log(disdata, "disdata")
    const afterdiscount = Math.round((data.paid_amount * 18) / 118)
    console.log(data.balance_account, "amount")


    setdata((prev) => ({
      ...prev,
      discount_account: disdata,
      balance_account: data.total_bill_amount - data.paid_amount - disdata,
      after_discount_tax: afterdiscount,
      basic_amount_after_discount: data.paid_amount - afterdiscount,
      total_amount: data.total_bill_amount - disdata,
      spaname: selector.details.data[""][0].spa_name,
      branchname: selector.details.data[""][0].spa_branch_name,
      createby: selector.details.data[""][0].manager_name
    }))
  }

  useEffect(() => {
    selectformgetdata()
    handlechangedate()
  }, []);
  useEffect(() => {
    if (selector.details.data !== null) {
      setbranch(selector.details.data[""][0].spa_branch_name);
    }
  }, [selector.details.data])

  const handlechange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setdata({ ...data, [name]: value });
    console.log(data, "data")
    console.log(e, "e")
  }

  const handleservicedata = (selectedOption) => {
    console.log(selectedOption)
    const resetFields = {
      payment_mode: '',
      reference_name: '',
      basic_amount: '',
      tax_amount: '',
      total_bill_amount: '',
      paid_amount: '',
      amount_in_word: '',
      balance_account: '',
      discount_account: '',
      basic_amount_after_discount: '',
      after_discount_tax: '',
      total_amount: ''
    };

    if(selectedOption==="membership" || "walking"){
      setpaydata([])
      setdata((prev)=>({
        ...prev,
        payment_mode:""
      }))
    }
    const dataservice = selectedOption.value
    console.log(dataservice, "data")

    const handlepayment_mode = async () => {
      const obj = {
        query: `[dbo].[spasoftware_web_proc_createservice_servicefeild_onchange]@value='{0}'`,
        queryArr: [`${dataservice}`]
      }
      try {
        const datapost = await Hexapi(obj)
        setpaydata(datapost.paymentmode)
        console.log(datapost.paymentmode, "datapost")
      } catch (error) {
        console.log("error")
      }
    }
    if(dataservice==="membership" || "walking"){
      setdata((prev)=>({
        ...prev,
        payment_mode:""
      }))
    }
    setdata({ ...data, customer_service: selectedOption ? selectedOption.value : '', ...resetFields });
    handlepayment_mode();
  }

  const handlepayment_modeChange = (selectedOption) => {
    const paymentMode = selectedOption ? selectedOption.value : '';
    const resetFields = paymentMode === 'Complentary' ? {
      basic_amount: '0',
      tax_amount: '0',
      total_bill_amount: '0',
      paid_amount: '0',
      amount_in_word: 'Zero',
      balance_account: '0',
      discount_account: '0',
      basic_amount_after_discount: '0',
      after_discount_tax: '0',
      total_amount: '0',
      spaname: selector.details.data[""][0].spa_name,
      branchname: selector.details.data[""][0].spa_branch_name,
      createby: selector.details.data[""][0].manager_name
    } : {
      basic_amount: '',
      tax_amount: '',
      total_bill_amount: '',
      paid_amount: '',
      amount_in_word: '',
      balance_account: '',
      discount_account: '',
      basic_amount_after_discount: '',
      after_discount_tax: '',
      total_amount: ''
    };
    setdata({ ...data, payment_mode: paymentMode, ...resetFields });
    console.log(data.payment_mode)
  };

  const handlesubmit = async (e) => {

    e.preventDefault()
    if (submitting) return;
    setSubmitting(true);
    const obj = {
      query: `[dbo].[spasoftware_web_proc_createnewserviceuser_insertdata]@dop_date='{0}',@customer_name='{1}',@customer_address='{2}',@customer_no='{3}',@customer_signature='{4}',@customer_service='{5}',@payment_mode='{6}', @reference_name='{7}',@basic_amount='{8}',@tax_amount='{9}',@total_bill_amount='{10}',@paid_amount='{11}',@word='{12}',@balance_amount='{13}',@discount_amount='{14}',@basic_amount_after_discount='{15}',@after_discount_tax='{16}',@total_amount='{17}',@total_service_hour='{18}',@branchname='{19}',@spa_name='{20}',@create_by='{21}',@security_code='{22}'`,
      queryArr: [
        `${data.dop_date}`, `${data.customer_name}`, `${data.customer_address}`, `${data.customer_no}`, `${data.customer_signature}`, `${data.customer_service}`, `${data.payment_mode}`, `${data.reference_name}`, `${data.basic_amount}`, `${data.tax_amount}`, `${data.total_bill_amount}`, `${data.paid_amount}`, `${data.amount_in_word}`,
        `${data.balance_account}`, `${data.discount_account}`, `${data.basic_amount_after_discount}`, `${data.after_discount_tax}`, `${data.total_amount}`, `${data.total_service_hour}`, `${data.branchname}`, `${data.spaname}`, `${data.createby}`, `${data.security_code}`
      ]
    };
    const resp = await Hexapi(obj);
    console.log(resp);
    setSubmitting(false)
    try {
      if (resp !== undefined && resp.service && resp.memberid) {

        if(resp.service[0].serviceid !==null && resp.memberid[0].memberid !==null ){
          navigate(`/viewservice/${encodeURIComponent(encryptData(resp.service[0].serviceid))}/${encodeURIComponent(encryptData( resp.memberid[0].memberid))}`)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // console.log(userid,"----------------")

  const therapistOptions = formdataget.map(therapist => ({
    value: therapist.refname,
    label: therapist.refname
  }));
  const managername = selector.details.data && selector.details.data[""][0].manager_name;
  therapistOptions.push({
    value: managername,
    label: managername
  });

  const handleTherapistChange = (selectedOption) => {
    setdata({ ...data, reference_name: selectedOption ? selectedOption.value : '' });
  };

  const treactmentoption = servicehourdata.map(hr => ({
    value: hr.servicehour,
    label: hr.service_hour
  }))
  const handletreatmentChange = (selectedOption) => {
    setdata({ ...data, total_service_hour: selectedOption ? selectedOption.value : '' });
  };

  const payment_modeoption = paydata.map(hr => ({
    value: hr.paymentmode,
    label: hr.paymentmode
  }));
  const service_option = [
    { value: 'walking', label: 'Walkin Service' },
    { value: 'membership', label: 'Membership Service' }
  ]



  return (
    <>

     <div className="container top-acc highlightBcg">
        <div className="row ">
          <div className="dashservice">
            <p className="name-text">
              CREATE NEW SERVICE
            </p>
            <div className="">
              <FaChevronDown onClick={() => { setshow(!show) }} className="" />
            </div >
          </div>
        </div>

        <div className="row bg-body-tertiary">
          {show && (
            <form onSubmit={handlesubmit} className="form-data">
              <div className="controls">
                <div className="control-group">
                  <label className="control-label">
                    DOP Date<a className="blink">*</a></label>
                  <div className="blinkTextDiv controls">
                    <input
                      id="dop_date"
                      type="text"
                      name="dop_date"
                      value={data.dop_date}
                      disabled
                      className="span7  popovers"

                    />

                    <a className="blink" id="blinkText">
                      *You can't change date : Contact Us
                    </a>
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Customer Name<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="customer_name"
                      value={data.customer_name}
                      id="customer_name"
                      onChange={handlechange}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Customer Address<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="customer_address"
                      id="customer_address"
                      value={data.customer_address}
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Customer Number<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      className="span6  popovers"
                      type="number"
                      value={data.customer_no}
                      name="customer_no"
                      id="customer_no"
                      required
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Customer Form Signature<a className="blink">*</a>
                  </label>
                  <div className="blinkTextDiv controls">
                    <select
                      className="span6  popovers"
                      data-placeholder="Select Customer Form Signature"
                      name="customer_signature"
                      id="customer_signature"
                      onChange={handlechange}
                      value={data.customer_signature}
                    >
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>


                    <a className="blink" id="blinkText">
                      *Ignore This
                    </a>
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Customer Service<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <Select
                      className="span6"
                        id="therapist_weekly_off"

                      name="customer_service"
                      options={service_option}
                      value={service_option.find(option => option.value === data.customer_service)}
                      onChange={handleservicedata}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Customer Service"
                    />
                  </div>
                </div>
                {
                  data.customer_service === 'membership' ? (
                    <div className="control-group">
                      <label className="control-label">
                        Reference Name<a className="blink">*</a></label>
                      <div className="controls">

                        <Select
                          className="span6"
                          id="therapist_weekly_off"
                          name="reference_name"
                          options={therapistOptions}
                          value={therapistOptions.find(option => option.value === data.reference_name)}
                          onChange={handleTherapistChange}
                          isClearable
                          classNamePrefix="react-select"
                          placeholder="Select Therapist"
                          required
                        />
                      </div>
                    </div>
                  ) : null
                }
                <div className="control-group">
                  <label className="control-label">
                    Payment Mode<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="payment_mode"
                      options={payment_modeoption}
                      value={payment_modeoption.find(option => option.value === data.payment_mode)}
                      onChange={handlepayment_modeChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Payment mode"
                    />
                  </div>
                </div>
                {(data.payment_mode === "Groupon" || data.payment_mode === "Make My trip") && (
                  <div className="control-group">
                    <label className="control-label">Security Code:</label>
                    <div className="controls">
                      <input
                        className="span6  popovers"
                        type="text"
                        value={data.security_code}
                        onChange={(e) => setdata({ ...data, security_code: e.target.value })}
                        required
                      />
                    </div>

                  </div>
                )}
                <div className="control-group">
                  <label className="control-label">
                    Basic Amount<a className="blink">*</a>
                  </label>
                  <input type="number" id="basic_amount"
                    className="span6  popovers"
                    name="basic_amount" required value={data.basic_amount} onChange={handlebasic_amount} />
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Tax Amount<a className="blink">*</a></label>
                  <div className="controls">
                    <input
                      type="number"
                      name="tax_amount"
                      id="tax_amount"
                      readOnly
                      value={data.tax_amount}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Total Bill Amount<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      className="span7  popovers"
                      name="total_bill_amount"
                      id="total_bill_amount"
                      readOnly
                      disabled
                      value={data.total_bill_amount}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Paid Amount<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="paid_amount"
                      id="paid_amount"
                      value={data.paid_amount}
                      onChange={handlepaid_amount_change}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Amount in Words<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      name="word"
                      id="word"
                      readOnly
                      value={data.amount_in_word}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Balance Amount<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      name="balance_amount"
                      id="balance_amount"
                      value={data.balance_account}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Discount Amount<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      name="discount_amount"
                      id="discount_amount"
                      value={data.discount_account}
                      onChange={handlediscount}
                      className="span6  popovers"

                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Basic Amount After Discount<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      name="discount_basic_amount"
                      id="discount_basic_amount"
                      data-trigger="hover"
                      readOnly
                      value={data.basic_amount_after_discount}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    After Discount Tax<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      id="after_discount_tax"
                      value={data.after_discount_tax}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Total Amount
                    <a className="blink" >
                    </a>
                  </label>
                  <div className="controls">
                    <input
                      type="number"
                      name="total_amount"
                      id="total_amount"
                      value={data.total_amount}
                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Total Services Hour
                    <a className="blink">
                    </a>
                  </label>
                  <div className="controls">
                    <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="total_service_hour"
                      options={treactmentoption}
                      value={treactmentoption.find(option => option.value === data.total_service_hour)}
                      onChange={handletreatmentChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Treatment hour"
                    />
                  </div>
                </div>
                <div className="createcancel_btn">
                  <button
                    type="submit"
                    onClick={handlesubmit}
                    className="btn-data"
                    disabled={submitting}
                  >
                   {submitting ? 'Submitting...' : 'Create'}
                  </button>
                  <button type="reset" className="cancel-btn" onClick={handleclear}>Cancel</button>
                </div>
              </div>
            </form>)}
        </div>
      </div>
    </>
  );
};

export default Createservice;