import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { FaChevronDown } from 'react-icons/fa';
import { encryptData } from '../cryptoutils/cryptoutils';
import { Link } from 'react-router-dom';

const Managermembership = ({data}) => {
  const[sho,setshow]=useState(true)

  function maskContact(contact) {
    if (contact.length < 4) {
        return contact;
    }
    const lastFourDigits = contact.slice(-4);
    const maskedPart = '*'.repeat(contact.length - 4);
    return maskedPart + lastFourDigits;
}

function formatDate(dateString) {
  let date = new Date(dateString);
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();


  return `${day}-${month}-${year}`;
}

  return (
   <>

<div className=' container mt-3 mb-5'>

<div className='bg-body-tertiary '>
      <div className='dashservice'>
        <p className='name-text'>Membership Account</p>
        <p><FaChevronDown onClick={()=>{setshow(!sho)}}/></p>
    </div>
       {sho && (
       <div className='px-2'>
       <Table responsive bordered className='mt-3 mb-3 bor-dis' id='managership' >
            <thead>
              <tr>
                <th>Sr No</th>
                <th>DOJ</th>
                <th>Branch Name</th>
                <th>Member Id</th>
                <th>Customer Name</th>
                <th>Customer Number</th>
                <th>Payment Mode</th>
                <th>Total Amount</th>
                <th>Paid Amount</th>
                <th>Pending Amount</th>
                <th>Total Service</th>
                <th>Used Service</th>
                <th>Balance Service</th>
                <th>Create By</th>
                <th>View Service</th>
                <th>Tip Account</th>
                <th>Pending Amount</th>
                </tr>
            </thead>
            <tbody>
               {data.membershipaccount && data.membershipaccount.map((ele,ind)=>{
                return(
                    <tr>
                    <td>{ind+1}</td>
                    <td className='text-info'>{formatDate(ele.cust_dop)} <p className=' text-danger'>actual_date <br/>{ele.actual_date}</p></td>
                    <td>{ele.branch_name}</td>
                  <td>{ele.member_id}</td>


                    <td>{ele.cust_name}</td>
                    <td>{maskContact(ele.cust_contact)}</td>
                    <td>{ele.payment_mode}</td>
                    <td>{ele.cust_total_amount}</td>
                    <td>{ele.cust_paid_amount}</td>
                    <td>{ele.cust_bal_amount}</td>
                    <td>{ele.cust_total_service}</td>
                    <td>{ele.cust_used_service}</td>
                    <td>{ele.cust_bal_service}</td>
                    <td>{ele.create_by}</td>
                    <td>
                    <Link to={`/viewservice/${ encryptData(ele.user_id)}/${encryptData(ele.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">service</Link>

                    <Link className="btn-click-2" to={`/renewservice/${ encryptData(ele.user_id)}/${encryptData(ele.member_id)}`} rel="noopener noreferrer" target="_blank">Renew</ Link>
                  </td>
                  <td> <Link to={`/tipaccount/${ encryptData(ele.user_id)}/${ encryptData(ele.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Tip</Link></td>
                  <td> <Link to={`/paidaccount/${ encryptData(ele.user_id)}/${ encryptData(ele.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Paid</Link></td>
                </tr>
                )
               })}
            </tbody>
        </Table>
        </div>)}
        </div>
        </div>



   </>
  )
}

export default Managermembership

