import 'bootstrap/dist/css/bootstrap.min.css';
import Customnavbar from './components/Customnavbar';
import Home from './pages/Home';
import { BrowserRouter as Router , Routes ,Route, useLocation } from 'react-router-dom';
import Priorappointment from './pages/Priorappointment';
import Appointment from './pages/Appointment';
import Quicksearch from './pages/Quicksearch';
import Addtherapist from './pages/Addtherapist';
import Mobileverification from './pages/Mobileverification';
import './App.css'
import MembershipAccount from './pages/MembershipAccount';
import MembershipRef from './pages/MembershipRef';
import DailySaleBook from './pages/DailySaleBook';
import Footer from './components/Footer';
import Attendance from './pages/Attendance';
import Createservice from './pages/Createservice';
import WalkinAccount from './pages/WalkinAccount';
import Complementary from './pages/Complementary';
import AllmembershipAccount from './pages/AllmembershipAccount';
import Therapistreport from './pages/Therapistreport';
import SpaMaster from './pages/SpaMaster';
import Salereport from './pages/Salereport';
import Saleformat from './pages/Saleformat';
import Totalsale from './pages/Totalsale';
import Nearby from './pages/Nearby';
import Membershipreference from './pages/Membershipreference';
import Dailysale from './pages/Dailysale';
import ModuleComplentary from './pages/ModuleComplentary';
import ModuleWalkin from './pages/ModuleWalkin';
import ModuleMembership from './pages/ModuleMembership';
import Spamarketing from './pages/Spamarketing';
import MainDashboard from './pages/MainDashboard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { fetchdata } from './store/slice/userdataslice'
import Viewmembershipserviceuser from './pages/Viewmembershipserviceuser';

import Profile from './pages/Profile';
import Quicksearchsubadmin from './pages/Quicksearchsubadmin';
import Advancesearchsubadmin from './pages/Advancesearchsubadmin';
import { Toaster } from 'react-hot-toast';
import { encryptData } from './cryptoutils/cryptoutils';
import Tipaccount from './pages/Tipaccount';
import PaidAccount from './pages/PaidAccount';
import Renew from './pages/Renew';
import Followup from './pages/Followup';
import Serviceadmin from './pages/Serviceadmin';
import Paidadmin from './pages/Paidadmin';
import Tipadmin from './pages/Tipadmin';
import { Tipaccountadmin } from './pages/Tipacountadmin';
import UpdateService from './pages/UpdateService';
import PaidAdminUpdate from './pages/PaidAdminUpdate';
import Invoice from './pages/Invoice';


// localStorage.setItem('Apipathurl','https://bagero.hexbss.xyz/')
// localStorage.setItem('userid','18')

function App() {

  const dispatch= useDispatch()
  useEffect(()=>{
    if(localStorage.getItem('userid')===null){
      window.location.href="https://bagero.hexbss.xyz/login"
    }else{
      dispatch(fetchdata())
    }

  },[])

  const path = window.location.pathname.includes("viewinvoice");

  return (

  <>
  { !path ?  <Router>
       <Customnavbar/>
      <Toaster containerStyle={{ position: 'absolute' }} />
      <Routes>
        <Route>
            <Route path="/" element={<Home/>}>
            <Route path='' element={<MainDashboard/>}/>
            <Route path='profile' element={<Profile/>}/>
            <Route path="priorappointment" element={<Priorappointment/>}/>
            <Route path="appointment" element={<Appointment/>}/>
            <Route path="viewservice/:userid/:memberid/" element={<Viewmembershipserviceuser/>}/>
            <Route path="viewserviceadmin/:userid/:memberid/" element={<Serviceadmin/>}/>
            <Route path='paidaccount/:userid/:memberid/' element={<PaidAccount/>}/>
            <Route path='paidaccountadmin/:userid/:memberid/' element={<Paidadmin/>}/>
            <Route path='tipaccount/:userid/:memberid/' element={<Tipaccount/>}/>
            <Route path='tipaccountadmin/:userid/:memberid/' element={<Tipaccountadmin/>}/>
            <Route path='renewservice/:userid/:memberid/' element={<Renew/>}/>
            <Route path='followup/:userid/:memberid/' element={<Followup/>}/>
            <Route path='updatecreatedata/:userid/' element={<UpdateService/>}/>
            <Route path='paidAdminUpdate/:userid/:memberid/:pendingaccountid/' element={<PaidAdminUpdate/>}/>
            <Route path='tipaccountAdminUpdate/:userid/:memberid/:tipaccountid/' element={<Tipadmin/>}/>
            <Route path='quicksearch' element={<Quicksearch/>}/>
            <Route path='quicksearchsubadmin' element={<Quicksearchsubadmin/>}/>
            <Route path='advancesearchsubadmin' element={<Advancesearchsubadmin/>}/>
            <Route path='createaccount' element={<Createservice/>}/>
            <Route path='addtherapist' element={<Addtherapist/>}/>
            <Route path='membership_account' element={<MembershipAccount/>}/>
            <Route path='membership_account_view' element={<ModuleMembership/>}/>
            <Route path='membership_account_view/:branchname' element={<ModuleMembership/>}/>
            <Route path='walkin_account' element={<WalkinAccount/>}/>
            <Route path='walkin_account_view' element={<ModuleWalkin/>}/>
            <Route path='complentary_account' element={<Complementary/>}/>
            <Route path='complentary_account_view' element={<ModuleComplentary/>}/>
            <Route path='mobileverification' element={<Mobileverification/>}/>
            <Route path='membership_account' element={<MembershipAccount/>}/>
            <Route path='dailysalebook' element={<DailySaleBook/>}/>
            <Route path='membership' element={<MembershipRef/>}/>
            <Route path='attendance' element={<Attendance/>}/>
            <Route path='allmembership' element={<AllmembershipAccount/>}/>
            <Route path='therapistreport' element={<Therapistreport/>}/>
            <Route path='spamaster' element={<SpaMaster/>}/>
            <Route path='spamarketing' element={<Spamarketing/>}/>
            <Route path='salereport' element={<Salereport/>}/>
            <Route path='saleformat' element={<Saleformat/>}/>
            <Route path='totalsale' element={<Totalsale/>}/>
            <Route path='nearbysale' element={<Nearby/>}/>
            <Route path='membershipref' element={<Membershipreference/>}/>
            <Route path='dailyservicebook' element={<Dailysale/>}/>

            </Route>

        </Route>
      </Routes>
      {/* <Footer/> */}
    </Router> : <Router>
      <Routes>
      <Route path='/viewinvoice/:userid/:branchtable' element={<Invoice/>}/>
        </Routes>
        </Router>}
  </>
  )
}

export default App



// import 'bootstrap/dist/css/bootstrap.min.css';
// import Customnavbar from './components/Customnavbar';
// import { BrowserRouter as Router , Routes ,Route } from 'react-router-dom';
// import Mobileverification from './pages/Mobileverification';
// import './App.css'
// import MembershipRef from './pages/MembershipRef';
// import DailySaleBook from './pages/DailySaleBook';
// import Attendance from './pages/Attendance';
// import AllmembershipAccount from './pages/AllmembershipAccount';
// import Therapistreport from './pages/Therapistreport';
// import SpaMaster from './pages/SpaMaster';
// import Salereport from './pages/Salereport';
// import Saleformat from './pages/Saleformat';
// import Totalsale from './pages/Totalsale';
// import Nearby from './pages/Nearby';
// import Membershipreference from './pages/Membershipreference';
// import Dailysale from './pages/Dailysale';
// import ModuleComplentary from './pages/ModuleComplentary';
// import Spamarketing from './pages/Spamarketing';
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux'
// import { fetchdata } from './store/slice/userdataslice'
// import { Toaster } from 'react-hot-toast';
// import Timepiker from './pages/Timepiker';
// import { Suspense, lazy } from "react"


// // import { fetchgetdash } from './store/slice/dashboarddata';

// localStorage.setItem('Apipathurl','https:/bagero.hexbss.xyz/')
// localStorage.setItem('userid','18')

// function App() {
//   const dispatch= useDispatch()
//   useEffect(()=>{
//     if(localStorage.getItem('userid')===null){
//       window.location.href="https://bagero.hexbss.xyz/login"
//     }else{
//       dispatch(fetchdata())
//     }

//   },[])
//   const Createservice=lazy(()=>import("./pages/Createservice"))
//   const Home=lazy(()=>import("./pages/Home"))
//   const Profile =lazy(()=>import("./pages/Profile"))
//   const MainDashboard =lazy(()=>import("./pages/MainDashboard"))
//   const Priorappointment =lazy(()=>import("./pages/Priorappointment"))
//   const Appointment =lazy(()=>import("./pages/Appointment"))
//   const Viewmembershipserviceuser =lazy(()=>import("./pages/Viewmembershipserviceuser"))
//   const Quicksearch =lazy(()=>import("./pages/Quicksearch"))
//   const MembershipAccount =lazy(()=>import("./pages/MembershipAccount"))
//   const Addtherapist =lazy(()=>import("./pages/Addtherapist"))
//   const Advancesearchsubadmin =lazy(()=>import("./pages/Advancesearchsubadmin"))
//   const Complementary =lazy(()=>import("./pages/Complementary"))
//   const ModuleWalkin =lazy(()=>import("./pages/ModuleWalkin"))
//   const WalkinAccount =lazy(()=>import("./pages/WalkinAccount"))
//   const ModuleMembership =lazy(()=>import("./pages/ModuleMembership"))
//   const Quicksearchsubadmin =lazy(()=>import("./pages/Quicksearchsubadmin"))

//   return (

//     <Router>
//        <Customnavbar/>
//        <Toaster containerStyle={{ position: 'absolute' }} />
//       <Routes>
//         <Route>
//             <Route path="/" element={<Suspense fallback={<h1>loading....</h1>}> <Home/></Suspense>}>
//             <Route path='' element={<Suspense fallback={<h1>loading....</h1>}> <MainDashboard/></Suspense>}/>
//             <Route path='profile' element={<Suspense fallback={<h1>loading....</h1>}> <Profile/></Suspense>}/>
//             <Route path="priorappointment"element={<Suspense fallback={<h1>loading....</h1>}> <Priorappointment/></Suspense>}/>
//             <Route path="appointment" element={<Suspense fallback={<h1>loading....</h1>}> <Appointment/></Suspense>}/>
//             <Route path="viewservice" element={<Suspense fallback={<h1>loading....</h1>}> <Viewmembershipserviceuser/></Suspense>}/>
//             <Route path='quicksearch' element={<Suspense fallback={<h1>loading....</h1>}> <Quicksearch/></Suspense>}/>
//             <Route path='quicksearchsubadmin' element={<Suspense fallback={<h1>loading....</h1>}> <Quicksearchsubadmin/></Suspense>}/>
//             <Route path='advancesearchsubadmin' element={<Suspense fallback={<h1>loading....</h1>}> <Advancesearchsubadmin/></Suspense>}/>
//             <Route path='createaccount' element={ <Suspense fallback={<h1>loading....</h1>}><Createservice/></Suspense>}/>
//             <Route path='addtherapist' element={<Suspense fallback={<h1>loading....</h1>}> <Addtherapist/></Suspense>}/>
//             <Route path='membership_account'element={<Suspense fallback={<h1>loading....</h1>}> <MembershipAccount/></Suspense>}/>
//             <Route path='membership_account_view' element={<Suspense fallback={<h1>loading....</h1>}> <ModuleMembership/></Suspense>}/>
//             <Route path='walkin_account' element={<Suspense fallback={<h1>loading....</h1>}> <WalkinAccount/></Suspense>}/>
//             <Route path='walkin_account_view' element={<Suspense fallback={<h1>loading....</h1>}> <ModuleWalkin/></Suspense>}/>
//             <Route path='complentary_account' element={<Suspense fallback={<h1>loading....</h1>}> <Complementary/></Suspense>}/>
//             <Route path='complentary_account_view' element={<ModuleComplentary/>}/>
//             <Route path='mobileverification' element={<Mobileverification/>}/>
//             <Route path='membership_account' element={<MembershipAccount/>}/>
//             <Route path='dailysalebook' element={<DailySaleBook/>}/>
//             <Route path='membership' element={<MembershipRef/>}/>
//             <Route path='attendance' element={<Attendance/>}/>
//             <Route path='allmembership' element={<AllmembershipAccount/>}/>
//             <Route path='therapistreport' element={<Therapistreport/>}/>
//             <Route path='spamaster' element={<SpaMaster/>}/>
//             <Route path='spamarketing' element={<Spamarketing/>}/>
//             <Route path='salereport' element={<Salereport/>}/>
//             <Route path='saleformat' element={<Saleformat/>}/>
//             <Route path='totalsale' element={<Totalsale/>}/>
//             <Route path='nearbysale' element={<Nearby/>}/>
//             <Route path='membershipref' element={<Membershipreference/>}/>
//             <Route path='dailyservicebook' element={<Dailysale/>}/>
//             <Route path='timepiker' element={<Timepiker/>}/>
//             {/* <Route path='logout' element={<Logout/>}/> */}
//             </Route>
//         </Route>
//       </Routes>
//       {/* <Footer/> */}
//     </Router>
// //  <>
// // <Datatablenew/>
// // </>
//   )
// }

// export default App

