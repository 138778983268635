import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";

const Renewadmin = ({memberId,userid,customer_name,cust_contact}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const selector= useSelector(state=>state)
    const [data, setdata] = useState({
    dop_date: "",
    customer_name: customer_name,
    customer_address: "",
    customer_no: cust_contact,
    customer_service:"",
    reference_name:"",
    payment_mode:"",
    basic_amount:"",
    tax_amount:"",
    total_bill_amount:"",
    paid_amount:"",
    amount_in_word:"",
    balance_account:"",
    discount_account:"",
    basic_amount_after_discount:"",
    after_discount_tax:"",
    total_amount:"",
    total_service_hour:"",
    branchname:"",
    spaname:"",
    createby:""
  })

  const[show,setshow]=useState(true)
  const[paydata,setpaydata]=useState({})
  const[formdataget,setformdataget]=useState({})


  const selectformgetdata=async()=>{
    if(selector.details.data!=null){
        const branchname=selector.details.data[""][0].spa_branch_name
        const objdata= {
            query:`[dbo].[spasoftware_web_proc_renewservice_getdata]@branchname='{0}'`,
            queryArr:[`${branchname}`]
         }
         try {
            const obj= await Hexapi(objdata)
            console.log(obj,"objget")
            setformdataget(obj)
         } catch (error) {
        console.log("error")
         }
    }

  }
  const handlechangedate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US",{
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    setdata((prev) => ({
      ...prev,
      dop_date: formattedDate,
    }));
  };

  const handlebasic_amount=(e)=>{
    const basicamount=e.target.value
    const basic=parseInt(basicamount)
    const taxdata=parseInt((basic*18)/100)
    const totalbillamunt= basic+taxdata
    setdata((prev)=>({
  ...prev,
  basic_amount:basic,
  tax_amount:taxdata,
  total_bill_amount:totalbillamunt
  })
  )}
  const handlepaid_amount_change = (e) => {
    const paiddata = e.target.value;
    if (!isNaN(paiddata)) {
        const paidvalue = parseInt(paiddata);
        const wordsamount = convertNumberToWords(paidvalue);
        const balanceamount= data.total_bill_amount-paidvalue

        setdata((prev) => ({
            ...prev,
            paid_amount: paidvalue,
            amount_in_word: wordsamount,
            balance_account:balanceamount
        }));
    } else {
        console.log('Invalid input: Please enter a valid number.');
    }
};
function convertNumberToWords(amount) {
  let words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  let atemp = amount.split(".");
  let number = atemp[0].split(",").join("");
  let n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
          received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
          n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              if (n_array[i] == 1) {
                  n_array[j] = 10 + parseInt(n_array[j]);
                  n_array[i] = 0;
              }
          }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              value = n_array[i] * 10;
          } else {
              value = n_array[i];
          }
          if (value != 0) {
              words_string += words[value] + " ";
          }
          if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Crores ";
          }
          if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Lakhs ";
          }
          if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Thousand ";
          }
          if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
              words_string += "Hundred and ";
          } else if (i == 6 && value != 0) {
              words_string += "Hundred ";
          }
      }
      words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}




const handlediscount=(e)=>{
  const discountdata= e.target.value
  const disdata=parseInt(discountdata)
  console.log(disdata,"disdata")
  const afterdiscount=Math.round((data.paid_amount*18)/118)
  console.log(data.balance_account,"amount")
  const databalance=data.balance_account

  setdata((prev)=>({
    ...prev,
    discount_account:disdata,
    balance_account:data.total_bill_amount-data.paid_amount-disdata,
    after_discount_tax:afterdiscount,
    basic_amount_after_discount:data.paid_amount-afterdiscount,
    total_amount:data.total_bill_amount-disdata,
    spaname:selector.details.data[""][0].spa_name,
    branchname:selector.details.data[""][0].spa_branch_name,
    createby:selector.details.data[""][0].create_by
  }))
}

  useEffect(()=>{
     selectformgetdata()
     handlechangedate()
  },[])

  const handlechange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setdata({ ...data, [name]: value });
    console.log(data,"data")
    console.log(e,"e")
  }

  const handleservicedata=(e)=>{
 const dataservice= e.target.value
 console.log(dataservice,"data")

  const handlepayment_mode=async()=>{
     const obj={
      query:`[dbo].[spasoftware_web_proc_createservice_servicefeild_onchange]@value='{0}'`,
      queryArr:[`${dataservice}`]
     }
     try {
      const datapost= await Hexapi(obj)
      setpaydata(datapost)
     console.log(datapost,"datapost")
     } catch (error) {
      console.log("error")
     }
  }
  setdata((prev)=>({
    ...prev,
    customer_service:dataservice
    }))
  handlepayment_mode()
  }

  const handlesubmit = async (e) => {
    e.preventDefault();

    if(selector.details.data!=null){
const obj = {
        query: `[dbo].[spasofware_web_proc_renewserviceuser_subadmin]@dop_date='{0}',@customer_name='{1}',@customer_address='{2}',@customer_no='{3}',@customer_service='{4}',@payment_mode='{5}', @reference_name='{6}',@basic_amount='{7}',@tax_amount='{8}',@total_bill_amount='{9}',@paid_amount='{10}',@word='{11}',@balance_amount='{12}',@discount_amount='{13}',@basic_amount_after_discount='{14}',@after_discount_tax='{15}',@total_amount='{16}',@total_service_hour='{17}',@branchname='{18}',@spa_name='{19}',@create_by='{20}',@renewid='{21}',@userid='{22}'`,
        queryArr: [
            `${data.dop_date}`, `${data.customer_name}`, `${data.customer_address}`, `${data.customer_no}`, `${data.customer_service}`, `${data.payment_mode}`, `${data.reference_name}`, `${data.basic_amount}`, `${data.tax_amount}`, `${data.total_bill_amount}`, `${data.paid_amount}`, `${data.amount_in_word}`,
            `${data.balance_account}`, `${data.discount_account}`, `${data.basic_amount_after_discount}`, `${data.after_discount_tax}`, `${data.total_amount}`, `${data.total_service_hour}`, `${data.branchname}`, `${data.spaname}`, `${data.createby}`,`${memberId}`,`${userid}`
        ]
    };
  const resp = await Hexapi(obj);
  console.log(resp)
  try {
    Swal.fire({
      text: `${resp[""][0]['msg']}`,
      icon: 'ok',
      customClass: {
          popup: 'my-custom-swal',
          title: 'my-custom-swal-title',
          content: 'my-custom-swal-content',
          confirmButton: 'my-custom-swal-confirm',
          cancelButton: 'my-custom-swal-cancel'
      },
  });
  setdata({
    customer_name: "",
    customer_address: "",
    customer_no: "",
    customer_signature: "",
    customer_service:"",
    reference_name:"",
    payment_mode:"",
    basic_amount:"",
    tax_amount:"",
    total_bill_amount:"",
    paid_amount:"",
    amount_in_word:"",
    balance_account:"",
    discount_account:"",
    basic_amount_after_discount:"",
    after_discount_tax:"",
    total_amount:"",
    total_service_hour:"",
    branchname:"",
    spaname:"",
    createby:""
  })
  } catch (error) {
      Swal.fire({
        text: "error",
        icon: 'ok',
        customClass: {
            popup: 'my-custom-swal',
            title: 'my-custom-swal-title',
            cancelButton: 'my-custom-swal-cancel'
        },
    });

  }
    }

}

return (
  <div className="container top-acc highlightBcg">
    <div className="row bg-body-tertiary ">
        <div className="dashservice">
          <p className="name-text">
            RENEW SERVICE
          </p>

            <FaChevronDown onClick={()=>{setshow(!show)}} />

        </div>
        </div>

      <div className="row bg-body-tertiary">
      {show && (
                  <form onSubmit={handlesubmit} className="form-data">

                    <div className="controls">
                    <div className="control-group">
              <label className="control-label">
              Renew Membership Id
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span7  popovers"
                  name="memberId"
                  id="customer_name"
                  value={memberId}
                  disabled
                />
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                DOP Date</label>
              <div className="blinkTextDiv controls">
                <input
                  id="dop_date"
                  type="text"
                  name="dop_date"
                  value={data.dop_date}
                  disabled
                  className="span7  popovers"

                />

              <a className="blink" id="blinkText">
                  *You can't change date : Contact Us
                </a>
                </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                Customer Name
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span7  popovers"
                  name="customer_name"
                  value={customer_name}
                  id="customer_name"
                  disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Customer Address
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span6  popovers"
                  name="customer_address"
                  id="customer_address"
                  value={data.customer_address}
                 onChange={handlechange}
                />
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                Customer Number
              </label>
              <div className="controls">
                <input
                  className="span7  popovers"
                  type="number"
                  value={cust_contact}
                  name="customer_no"
                  id="customer_no"
                disabled

                />
              </div>
            </div>


            <div className="control-group">
            <label className="control-label">
                Customer Service
              </label>
              <div className="controls">
                <select
                  className="span6  popovers"
                  data-placeholder="Select Customer Service"
                  name="customer_service"
                  id="customer_service"
                  required
                  value={data.customer_service}
                  onChange={handleservicedata}
                >
                  <option selected>Select Customer Service</option>
                  <option value='membership'>Membership Service</option>
                </select>
              </div>
            </div>

  <div className="control-group">
    <label className="control-label">
      Reference Name</label>
    <div className="controls">
      <select
        data-placeholder="Select Reference Name"
        name="reference_name"
        id="reference"
        value={data.reference_name}
        onChange={handlechange}
        className="span6  popovers"
      >
        <option>Select Reference name</option>
        {formdataget.therapistname ?
          formdataget.therapistname.map((ref) => {
            return (
              <option value={ref.refname}>{ref.refname}</option>
            );
          }) : null}
          <option>Ram Back Office</option>
      </select>
    </div>
  </div>


        <div className="control-group">
        <label className="control-label">
                Payment Mode
              </label>
              <div className="controls">
                <select
                  data-placeholder="Select Payment Mode"
                  name="payment_mode"
                  id="payment_mode"
                  onChange={handlechange}
                  value={data.payment_mode}
                  className="span6  popovers"

                >
                    <option>Select payment mode</option>
                 {paydata.paymentmode && paydata.paymentmode.map((pay)=>{
                   return(
                      <option value={pay.paymentmode}>{pay.paymentmode}</option>
                   )
                 })}
                </select>
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                Basic Amount
              </label>
                <input type="number" id="basic_amount"
                   className="span6  popovers"
                 name="basic_amount" required  value={data.basic_amount} onChange={handlebasic_amount}/>
            </div>
            <div className="control-group">
            <label className="control-label">
                Tax Amount</label>
              <div className="controls">
                <input
                  type="number"
                  name="tax_amount"
                  id="tax_amount"
                  readOnly
                  value={data.tax_amount}
                   className="span7  popovers"
                  disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Total Bill Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                   className="span7  popovers"
                  name="total_bill_amount"
                  id="total_bill_amount"
                  readOnly
                  disabled
                  value={data.total_bill_amount}
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Paid Amount
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span6  popovers"
                  name="paid_amount"
                  id="paid_amount"
                  value={data.paid_amount}
                  onChange={handlepaid_amount_change}
                 />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Amount in Words
              </label>
              <div className="controls">
                <input
                  type="text"
                  name="word"
                  id="word"
                  readOnly
                  value={data.amount_in_word}
                   className="span7  popovers"
                  disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Balance Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="balance_amount"
                  id="balance_amount"
                  value={data.balance_account}
                   className="span7  popovers"
                  disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Discount Amount
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="discount_amount"
                  id="discount_amount"
                  value={data.discount_account}
                  onChange={handlediscount}
                   className="span6  popovers"

                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Basic Amount After Discount
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="discount_basic_amount"
                  id="discount_basic_amount"
                  data-trigger="hover"
                  readOnly
                  value={data.basic_amount_after_discount}
                   className="span7  popovers"
                  disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                After Discount Tax
              </label>
              <div className="controls">
                <input
                  type="number"
                  id="after_discount_tax"
                  value={data.after_discount_tax}
                   className="span7  popovers"
                  disabled
                />
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                Total Amount
                <a className="blink" >
                </a>
              </label>
              <div className="controls">
                <input
                  type="number"
                  name="total_amount"
                  id="total_amount"
               value={data.total_amount}
                className="span7  popovers"
               disabled
                />
              </div>
            </div>

            <div className="control-group">
            <label className="control-label">
                Total Services Hour
                <a className="blink">
                </a>
              </label>
              <div className="controls">
                <select
                  data-placeholder="Add Services Hour"
                  // tabindex="-1"
                  name="total_service_hour"
                  id="total_service"
                 onChange={handlechange}
                 value={data.total_service_hour}
                 className="span6  popovers"

                >
                   <option selected>Select Service Hour</option>
                  {formdataget.servicehour && formdataget.servicehour.map((ser)=>{
                  //  console.log(ser,"ser")
                   return(
                      <>
                       <option  value={ser.servicehour}>{ser.service_hour}</option>
                      </>
                   )
                  })}
                </select>
              </div>
            </div>
            <div className="createcancel_btn">
              <button
              type="submit"
              onClick={handlesubmit}
              className="btn-data"
              >
               Renew
              </button>
              <button type="reset" className="cancel-btn btn">Cancel</button>
            </div>
            </div>
          </form>)}
      </div>
      </div>
);
};

export default Renewadmin