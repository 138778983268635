import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';
import { lineSpinner } from 'ldrs'
import { default as Select } from 'react-select';
import { useLocation, useParams } from "react-router-dom";
import { decryptData } from "../cryptoutils/cryptoutils";

lineSpinner.register();

const Tipaccount = () => {
  const{userid,memberid}=useParams()
const userId=decryptData(userid)
const memberId=decryptData(memberid)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selector = useSelector(state => state);

  // console.log(selector.details.data[""][0].user_role, "-------------createservice")
  const spa_branch_name = selector.details.data && selector.details.data[""][0].spa_branch_name;
  const manager_name = selector.details.data && selector.details.data[""][0].manager_name;
  const spa_name = selector.details.data && selector.details.data[""][0].spa_name;
  const [loading, setloading] = useState(false)
  const [show, setshow] = useState(true);
  const [showTable, setshowTable] = useState(true);
  const [paidAmount, setPaidAmount] = useState('');
  const [dataAvailable, setDataAvailable] = useState(false);
  const [data, setData] = useState(null);
  const [amountInWords, setAmountInWords] = useState('');
  const [seventyPercentAmount, setSeventyPercentAmount] = useState('');
  const [thirtyPercentAmount, setThirtyPercentAmount] = useState('');
  const [gstAmount, setGstAmount] = useState('');
  const [basicAmount, setBasicAmount] = useState('');
  const [payment, setpayment] = useState([]);
  const [therapistName, setTherapistName] = useState([]);
  const[submitting,setSubmitting]=useState(false)
  const [formValues, setFormValues] = useState({
    paymentmode: "",
    therapist_name: '',
    total_tip_amount: '',
    amount_in_words: '',
    gst_amount: '',
    basic_amount: '',
    dop_date: '',
    tip_amount_80: '',
    tip_amount_20: '',
    operater_by: '',
    status: ''
  });


  const handlepaid_amount_change = (e) => {
    console.log(e.target.value)
    const paidData = e.target.value;
    if (!isNaN(paidData)) {
      const paidValue = parseFloat(paidData);
      const wordsAmount = convertNumberToWords(paidValue);
      const seventyPercent = paidValue * 0.7;
      const thirtyPercent = paidValue * 0.3;
      const gst = paidValue * 0.18;
      const basic = paidValue - gst;

      setPaidAmount(paidValue);
      setAmountInWords(wordsAmount);
      setSeventyPercentAmount(seventyPercent);
      setThirtyPercentAmount(thirtyPercent);
      setGstAmount(gst);
      setBasicAmount(basic);
      setFormValues((prev) => ({
        ...prev,
        total_tip_amount: e.target.value,
        tip_amount_80: seventyPercent,
        tip_amount_20: thirtyPercent,
        amount_in_words: wordsAmount,
        gst_amount: gst,
        basic_amount: basic
      }));
      console.log("Amount_in_words: " + wordsAmount + ",",
        "70% Tip: " + seventyPercent + ",",
        "30% Tip: " + thirtyPercent + ",",
        "basic_amount: " + basic + ",",
        "GST: " + gst)
    } else {
      console.log('Invalid input: Please enter a valid number.');
    }
  };
  function queryData() {
    let obj = {
      query: `[dbo].[spaSoftware_web_proc_Tipmaster_GetData]@memberId='{0}'`,
      queryArr: [`${memberId}`]
    }
    Hexapi(obj).then((resp) => {
      console.log(resp);
      setData(resp.tipmasterdata || []);
      setpayment(resp.paymentmode || []);
      setTherapistName(resp.therapist_name || []);
      setDataAvailable(true)
    }).catch((err) => {
      console.log(err, "err");
    })
  };
  useEffect(() => {
    queryData();
  }, []);

  const therapistOptions = therapistName.map(therapist => ({
    value: therapist.therapist_name,
    label: therapist.therapist_name
  }));
  const paymentmode = payment.map(payment => ({
    value: payment.paymentmode,
    label: payment.paymentmode
  }));

  const managername = selector.details.data && selector.details.data[""][0].manager_name;
  therapistOptions.push({
    value: managername,
    label: managername
  });
  const handleTherapistChange = (selectedOption) => {
    setFormValues({ ...formValues, therapist_name: selectedOption ? selectedOption.value : '' });
  };
  const handleChangePaymentMode = (selectedOption) => {
    setFormValues({ ...formValues, paymentmode: selectedOption ? selectedOption.value : '' });
  };
  function convertNumberToWords(amount) {
    let words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    let atemp = amount.split(".");
    let number = atemp[0].split(",").join("");
    let n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  const handleTherapistNameChange = (e) => {
    console.log(e.target.value)
    setFormValues((prev) => ({
      ...prev,
      therapist_name: e.target.value
    }));
  }
  const handleChangeReport = (date, name) => {
    if (date) {
      setFormValues({ ...formValues, [name]: date.toISOString().split('T')[0] });
    } else {
      setData({ ...data, [name]: '' });
    }
  };

  const setCurrentDateIfEmpty = name => {
    if (!data[name]) {
      setFormValues({ ...formValues, [name]: new Date().toISOString().split('T')[0] });
    }
  };

  const handleSubmit = () => {
    if (submitting) return;

    setSubmitting(true);
    console.log(formValues.total_tip_amount)
    let obj = {
      query: `[dbo].[spaSoftware_web_proc_TipMaster_InsertData]@userId='{0}',@memberId='{1}',@branchName='{2}',@serviceDate='{3}',@paymentMode='{4}',@therapistName='{5}',@totalTipAmount='{6}',@tipAmount_80='{7}',@tipAmount_20='{8}',@tipPaidBy='{9}',@spa_name='{10}',@amount_in_words='{11}',@managerName='{12}',@gstAmount='{13}',@basicAmount='{14}'`,
      queryArr: [`${userId}`, `${memberId}`, `${spa_branch_name}`, `${formValues.dop_date}`, `${formValues.paymentmode}`, `${formValues.therapist_name}`, `${formValues.total_tip_amount}`, `${formValues.tip_amount_80}`, `${formValues.tip_amount_20}`, `${manager_name}`, `${spa_name}`, `${formValues.amount_in_words}`, `${manager_name}`, `${formValues.gst_amount}`, `${formValues.basic_amount}`]
    }
    Hexapi(obj).then((resp) => {
      console.log(resp);
      queryData()
      if (resp) {
        try {
          Swal.fire({
            text: `${resp[""][1]['msg']}`,
            icon: 'ok',
            customClass: {
              popup: 'my-custom-swal',
              title: 'my-custom-swal-title',
              content: 'my-custom-swal-content',
              confirmButton: 'my-custom-swal-confirm',
              cancelButton: 'my-custom-swal-cancel'
            },
          })
          setSubmitting(false)
        } catch (error) {
          Swal.fire({
            text: `${resp[""][0]['msg']}`,
            icon: 'ok',
            customClass: {
              popup: 'my-custom-swal',
              title: 'my-custom-swal-title',
              cancelButton: 'my-custom-swal-cancel'
            },
          });
        }
      }
    })
  }


  return (
    <div>
      {loading ?
      <div className="text-center mt-5"><l-line-spinner
        size="40"
        stroke="3"
        speed="1"
        color="black"
        className="relative"

      ></l-line-spinner></div> :

      <div className="container top-acc highlightBcg">
        <div className="row">
          <div className="dis-d">

            <p className="font-dis">
            Tip Accounts
            </p>
            <div className="head-hide-btn">
              <FaChevronDown onClick={() => { setshow(!show) }} className="hideShowBtn" />
            </div >
          </div>
        </div>

        <div className="row bg-body-tertiary
  ">
          {show && (
            <div className="form-data" >
              <div className="controls">
                <div className="control-group">
                  <label className="control-label">
                    Tip Recieve from client Date</label>
                  <div className="blinkTextDiv controls">
                    {/* <input
                      id="dop_date"
                      type="date"
                      name="dop_date"
                      value={formValues.dop_date}
                      required
                      className="span7  popovers"
                      onChange={handleChange}
                    /> */}
                    <DatePicker
                      selected={formValues.dop_date}
                      onChange={(date) => handleChangeReport(date, 'dop_date')}
                      className="span6  popovers"
                      dateFormat="dd/MM/yyyy"
                      onFocus={() => setCurrentDateIfEmpty('dop_date')}
                    />
                    <a className="blink" id="blinkText">
                      *You can not Enter Past Days TIP - Contact Us
                    </a>
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Select Payment Mode
                  </label>
                  <div className="blinkTextDiv controls">

                    <Select
                      className="span6"
                      id="customer_signature"
                      name="customer_signature"
                      options={paymentmode}
                      value={paymentmode.find(option => option.value === formValues.paymentmode)}
                      onChange={handleChangePaymentMode}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select payment mode"
                      required
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Select Therapist Name
                  </label>
                  <div className="blinkTextDiv controls">

                    <Select
                      className="span6"
                      id="customer_signature"
                      name="customer_signature"
                      options={therapistOptions}
                      value={therapistOptions.find(option => option.value === formValues.therapist_name)}
                      onChange={handleTherapistChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Therapist Name"
                      required
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    Total Tip Amount
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="customer_name"
                      id="customer_name"
                      required
                      onChange={handlepaid_amount_change}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Amounts in Words<a className="blink">*</a>
                  </label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span7  popovers"
                      name="customer_address"
                      id="customer_address"
                      value={amountInWords}
                      readOnly
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    70% Tip Amount
                  </label>
                  <div className="controls">
                    <input
                      className="span7  popovers"
                      type="text"
                      value={seventyPercentAmount}
                      name="customer_no"
                      id="customer_no"

                      readOnly

                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">
                    30% Tip Amount
                  </label>
                  <div className="controls">
                    <input
                      className="span7  popovers"
                      type="text"
                      readOnly
                      value={thirtyPercentAmount}
                      name="customer_no"
                      id="customer_no"

                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">
                    Basic Amount
                  </label>
                  <input type="text" id="basic_amount"
                    className="span7  popovers"
                    readOnly
                    name="basic_amount"
                    value={basicAmount}
                  />
                </div>
                <div className="control-group">
                  <label className="control-label">
                    GST Amount</label>
                  <div className="controls">
                    <input
                      type="text"
                      name="tax_amount"
                      id="tax_amount"
                      readOnly
                      value={gstAmount}

                      className="span7  popovers"
                      disabled
                    />
                  </div>
                </div>


                <div className="createcancel_btn">
                  <button
                    // type="submit"
                    className="btn-data"
                    onClick={handleSubmit}
                    disabled={submitting}
                  >
                    {submitting ? 'Submitting...' : 'Paid'}
                  </button>
                  <button type="reset" className="cancel-btn btn">Cancel</button>
                </div>
              </div>
            </div>)}

        </div>
      </div>}




          {dataAvailable ?
            // setTimeout(() => {
            <div className=" mt-3 container top-acc highlightBcg">

              <div className="row bg-body-tertiary mt-4">
<div className="dashservice">
  <p className="name-text"> Tip Amount Details</p>
  <FaChevronDown onClick={()=>{setshowTable(!showTable)}}/>
</div>
 <div>
 {showTable && ( <table className=" table table-striped  table-bordered table-advance table-hover table-responsive table-hover mt-3 mb-3 bor-dis">
    <thead className="">
    <tr>
                        <th>Member Id</th>
                        <th>Branch Name</th>
                        <th>Tip Recieved from Client Date</th>
                        <th>Payment Type</th>
                        <th>Therapist</th>
                        <th>Total Tip Amount</th>
                        <th>70% Tip Amount</th>
                        <th>30% Tip Amount</th>
                        <th>Operater By</th>
                        <th>Status</th>
                      </tr>
    </thead>

    <tbody>
    {data === null ? (<l-line-spinner
                        size="40"
                        stroke="3"
                        speed="1"
                        color="black"
                        className="relative"

                      ></l-line-spinner>) :
                        (data && data.map((ele) => (
                          <tr key={ele.member_id}>
                            <td>{ele.status === 1 ? <span className='active'></span> : <span className='inactive'></span>}{ele.member_id}</td>
                            <td>{ele.branch_name}</td>
                            <td>{ele.service_date.date.split(' ')[0]}</td>
                            <td>{ele.payment_mode}</td>
                            <td>{ele.therapist_name}</td>
                            <td>{ele.total_tip_amount}</td>
                            <td>{ele.tip_amount_80}</td>
                            <td>{ele.tip_amount_20}</td>
                            <td>{ele.tip_paid_by}</td>
                            <td>{ele.tip_status === 1 ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>

                          </tr>
                        )))
                      }
    </tbody>
  </table>)}
 </div>
      </div>


              </div>


            : <p>No data available</p>
          }
        </div>

  );
};

export default Tipaccount