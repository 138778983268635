import React, {useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { Spinner, Table } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronDown } from "react-icons/fa";
import './attendance.css'
import { Form } from 'react-bootstrap';
import PaidAccount from "./PaidAccount";
import Serviceadmin from "./Serviceadmin";
import UpdateService from "./UpdateService";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { useSelector } from "react-redux";
import { default as Select } from 'react-select';
import { maskContact } from "../customfunction/dateformatmonth";
import { lineSpinner } from 'ldrs'
import Paidadmin from "./Paidadmin";
import { Link } from "react-router-dom";
import { encryptData } from "../cryptoutils/cryptoutils";
lineSpinner.register()


export default function Advancesearchsubadmin() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const selector=useSelector(state=>state)
  const[show,setShow]=useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const[visible,setvisi]=useState(true)
  const [isFormVisible, setFormVisible] = useState(true);
  const[data,setdata]=useState({
    searchoption:"",
    enter_details:""
  })
  const [renewForm, setRenewForm] = useState(false);
  const[branch,setbranch]=useState(null)
  const[tipshow,settipshow]=useState(false)
  const[paidshow,setpaidshow]=useState(false)
  const[pending,setcust_pending]=useState(null)
  const[report,setquicksearch]=useState([])
 const[loading,setloading]=useState(false)
 const [branchdata,setbranchdata]=useState([])


 useEffect(()=>{
 const handlebranchdata=async()=>{
    const obj={
        query:`[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr:[]
     }
     const getdata=await Hexapi(obj)
     console.log(getdata)
     setbranchdata(getdata.branch)
 }
 handlebranchdata()
 },[])
  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const handlechange=(e)=>{
 const{name,value}=e.target
 setdata({...data,[name]:value})
  }

  const handlesubmit=async(e)=>{
    e.preventDefault()
    setloading(true)


      const obj={
        query:`[dbo].[spasoftware_web_proc_advancesearch_subadmin_query]@searchoption='{0}',@enterdetails='{1}'`,
        queryArr:[`${data.searchoption}`,`${data.enter_details}`]
      }
     try {
      const getdata=await Hexapi(obj)
      console.log(getdata)
      setquicksearch(getdata.quickadvancesearch)

     } catch (error) {
            console.log(error)
     }


    setloading(false)

  }

function formatDate(dateString) {
  // Create a new Date object from the input date string
  let date = new Date(dateString);

  // Define an array of month names
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract the day, month, and year from the Date object
  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();

  // Return the formatted date string
  return `${day}-${month}-${year}`;
}

const handleSearchOptionChange = (selectedOption) => {
  setdata({ ...data, searchoption: selectedOption ? selectedOption.value : '' });
};


const searchOptions = [
  { value: "cust_name", label: "Customer Name" },
  { value: "cust_contact", label: "Customer Number" },
  { value: "member_id", label: "Customer Member Id" },
];

const columns = useMemo(() => [
          { Header: "Sr No", accessor: (_, i) => i + 1 },
          { Header: "DOJ", accessor:"cust_dop" ,Cell: ({row})=>(<div className="text-info">{formatDate(row.original.cust_dop)}<span className="text-danger"> actual date <br/> {row.original.actual_date}</span> </div>)},
          { Header: "Branch Name", accessor: "branch_name" },
          {
            Header: "Member Id",
            accessor: "member_id",
            Cell: ({ row }) => (
              <Link
              className="curpoint"
              to={`/updatecreatedata/${ encryptData(row.original.user_id)}`} rel="noopener noreferrer" target="_blank">
                {row.original.member_id}
              </Link>
            )
          },
          { Header: "Customer Name", accessor: "cust_name" },
          { Header: "Customer Number", accessor:"cust_contact",Cell: ({row}) => maskContact(row.original.cust_contact) },
          { Header: "Payment Mode", accessor: "payment_mode" },
          { Header: "Total Amount", accessor: "cust_total_amount" },
          { Header: "Paid Amount", accessor: "cust_paid_amount" },
          { Header: "Pending Amount", accessor: "cust_bal_amount" },
          { Header: "Total Service", accessor: "cust_total_service" },
          { Header: "Used Service", accessor: "cust_used_service" },
          { Header: "Balance Service", accessor: "cust_bal_service" },
          { Header: "Create By", accessor: "create_by" },
          {
            Header: "View Service",
            accessor: "booking",
            Cell: ({ row }) => (
              <>

             <Link to={`/viewserviceadmin/${ encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">service</Link>
              </>
            )
          },

          {
            Header: "Pending",
            accessor: "pending",
            Cell: ({ row }) => (
              <Link to={`/paidaccountadmin/${ encryptData(row.original.user_id)}/${ encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Paid</Link>
            )
          }
        ], []);
const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  setPageSize,
  state: { pageIndex, pageSize, globalFilter },
  setGlobalFilter,
} = useTable(
  {
    columns,
    data: report,
    initialState: { pageIndex: 0, pageSize: itemsPerPage },
  },
  useGlobalFilter,
  useSortBy,
  usePagination
);

const totalEntries = useMemo(() => report.length, [report]);
const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
  <div className='container mb-5'>
  <div className='row topatt'>
 <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>Search Report</h4></p>
  <FaChevronDown className='text-new' onClick={()=>{setvisi(!visible)}}/>
 </div>
{visible && (<>
  <div>
  <Form onSubmit={handlesubmit}>
   <div className='form-data mt-3'>
    <Form.Label>
     Select Search Option
    </Form.Label>
    <Select
                       id="therapist_weekly_off"
                      name="searchoption"
                      options={searchOptions}
                      value={searchOptions.find(option => option.value === data.searchoption)}
                      onChange={handleSearchOptionChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Search Option"
                    />
    </div>
   <div className='form-data mt-3'>
    <Form.Label>
    Enter Details
    </Form.Label>
    <input
    name="enter_details"
      value={data.enter_details}
      onChange={handlechange}
      className="span8"
     />

    </div>
   <hr/>
    <div className='newshow'>
      <button id='show_btn' >Show Report</button>

    </div>
    <hr/>
  </Form>
</div>
</>)}
  </div>


         <div className="row">
          <div className="disapp">

      </div>
      </div>

        <div className=" row bg-body-tertiary mt-5">
        <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>Membership Accounts <span className="text-danger">Branch :{selector.details.data!==null ? selector.details.data[""][0].spa_branch_name : null}</span></h4></p>
  <FaChevronDown className='text-new' onClick={toggleFormVisibility}/>
 </div>

  <div className=" px-2">
          { isFormVisible && (
 <div className="mb-3">
 <div className="d-flex justify-content-between mt-2">
 <select
     value={pageSize}

     onChange={(e) => {
       setItemsPerPage(Number(e.target.value));
       setPageSize(Number(e.target.value));
     }}
   >
     {[10, 20, 30, 40, 50,75,100].map(pageSize => (
       <option key={pageSize} value={pageSize}>
         Show {pageSize}
       </option>
     ))}
   </select>
 <input
   value={globalFilter || ""}
   onChange={(e) => setGlobalFilter(e.target.value)}
   className=" mb-3"
   placeholder="search..."
 />

   </div>
 <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
   <thead>
     {headerGroups.map(headerGroup => (
       <tr {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
           <th {...column.getHeaderProps(column.getSortByToggleProps())}>
             {column.render("Header")}
             <span>
               {column.isSorted
                 ? column.isSortedDesc
                   ? " 🔽"
                   : " 🔼"
                 : ""}
             </span>
           </th>
         ))}
       </tr>
     ))}
   </thead>
   <tbody {...getTableBodyProps()}>
     {loading ? (
       <tr>
       <td colSpan={17} className="text-center">
       <Spinner animation="border" />
     </td>
        </tr>
     ) : report.length === 0 ? (
       <tr>
         <td className="fs-6" colSpan={17}>No Data Available</td>
       </tr>
     ) : (
       page.map(row => {
         prepareRow(row);
         return (
           <tr {...row.getRowProps()}>
             {row.cells.map(cell => (
               <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
             ))}
           </tr>
         );
       })
     )}
   </tbody>
 </Table>
<div className="d-flex justify-content-between mb-3">
<span>
Showing {startIndex}-{endIndex} of {totalEntries} entries
{remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
<div className="pagination">
   <button onClick={() => previousPage()} disabled={!canPreviousPage}>
     Previous
   </button>
   <span>
     Page{' '}
     <strong>
       {pageIndex + 1} of {pageOptions.length}
     </strong>{' '}
   </span>
   <button onClick={() => nextPage()} disabled={!canNextPage}>
     Next
   </button>

 </div>
</div>
</div>
        )}
        </div>
        </div>
        </div>
    </>
  );
}