import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import "./Invoice.css";
import jsPDF from 'jspdf';
// import 'jspdf-autoTable';
import html2canvas from 'html2canvas';
import { Table } from 'react-bootstrap';
import { LuScissorsLineDashed } from "react-icons/lu";
import { useParams } from 'react-router-dom';
import { decryptData } from '../cryptoutils/cryptoutils';
import { useSelector } from 'react-redux';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { formatDate } from '../customfunction/dateformatmonth';


function Invoice() {

    const {userid,branchtable}=useParams()
    const userId=decryptData(userid)
    const branchTable=decryptData(branchtable)
    console.log(userId,branchTable,"userid")
    const [sendResp, setSendResp] = useState('');
    const [sendResp1, setSendResp1] = useState('');
    const [showButton,setShowButton]=useState(true)


    useEffect(() => {
   if(branchTable !==null && userId !==null){
    let obj = {
        query: `[dbo].[spasoftware_web_proc_walkinInvoice_invoicedata]@branch_table_name='{0}',@invId='{1}'`,
        queryArr: [`${branchTable}`, `${userId}`]
    }
    Hexapi(obj).then((resp) => {
        console.log(resp,"--------------");
        setSendResp(resp.invoicedata[0]|| {});
        setSendResp1(resp.companydetails[0]|| {});

    })
}
    }, [branchTable,userId])

    const handlePrint = useReactToPrint({
        content: () => document.getElementById('invoice-content'),
        onBeforeGetContent: () => {
            setShowButton(false);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setShowButton(true);
        },
    });
    const handleDownloadPDF = () => {
        html2canvas(document.querySelector("#invoice-content")).then((canvas) => {
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/png");
            pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
            pdf.save(`invoice.pdf`);
        });
    };
    return (
        < div className='main-invoice-center'>

            <div className="invoice-container">
                <div id='invoice-content' >
                    <div className="invoice-section table-head" >
                        <Table border="0" cellSpacing="1" cellPadding="1" >
                            <tr className='invoice-change'>
                                <td valign="top" className="no-padding invoice-change">
                                    <div className=' d-flex  justify-content-between align-item-center'>
                                            <p >GSTIN</p>
                                            <p>{sendResp1.gst_no}</p>
                                            <div className=' text-left'>
                                                <p className="invoice-title">INVOICE</p>
                                            </div>
                                            <div >
                                                (ORIGINAL FOR BUYERS)
                                                <p>PH : {sendResp1.branch_contact_no}</p>
                                                <p>Mob : {sendResp1.branch_mobile_no}</p>
                                            </div>
                                            </div>

                                    <div className=' text-center' >

                                                <h5>{sendResp1.spa_name}</h5>
                                                <p>(UNIT OF {sendResp1.company_name})</p>
                                                <h6>{sendResp1.branch_address}</h6>

                                    </div>
                                </td>
                            </tr>
                        </Table>

                        <Table  border="0" cellSpacing="1" cellPadding="1">
                            <tr>
                                <td>
                                    <Table border="0" cellSpacing="1" cellPadding="1">
                                        <tr className='invoice-change'>
                                            <td>NAME</td>
                                            <td>{sendResp.cust_name}</td>
                                            <td>INVOICE NO.</td>
                                            <td>{userId}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>ADDRESS</td>
                                            <td>{sendResp.cust_address}</td>
                                            <td>BILL DATE</td>
                                            <td>{ formatDate (sendResp?.booking_date?.date.split(' ')[0])}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>CONTACT NO.</td>
                                            <td>{sendResp.cust_contact}</td>
                                            <td>PAYMENT TYPE</td>
                                            <td>{sendResp.payment_type}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>SERVICE TYPE</td>
                                            <td>{sendResp.service_type}</td>
                                        </tr>
                                    </Table>
                                </td>
                            </tr>
                        </Table>

                        <Table  border="0" cellSpacing="1" cellPadding="1">
                            <tr className="invoice-change">
                                <td valign="top" className='invoice-table-main '>
                                    <Table  className="invoice-table" cellPadding="1" cellSpacing="1">
                                        <tr className="invoice-table-header invoice-change">
                                            <td rowSpan="2">Sno.</td>
                                            <td rowSpan="2">Description</td>
                                            <td rowSpan="2">HSN/SAC</td>
                                            <td rowSpan="2">Qty.</td>
                                            <td rowSpan="2">Price</td>
                                            <td >SGST</td>
                                            <td>CGST</td>
                                            <td rowSpan="2">Total</td>
                                            <td rowSpan="2">Discount</td>
                                            <td rowSpan="2">Received Amount</td>
                                            <td rowSpan="2">Outstanding Amount</td>
                                            <td>SGST.</td>
                                            <td>CGST.</td>
                                        </tr>
                                        <tr className="invoice-table-header invoice-change">
                                            <td align="center" >9%</td>
                                            <td align="center">9%</td>
                                            <td align="center" >9%</td>
                                            <td align="center">9%</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Oil Massage</td>
                                            <td align="right" >0</td>
                                            <td align="right" >1</td>
                                            <td align="right" >{sendResp.basic_amount}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.total_bill_amount}</td>
                                            <td align="right" >{sendResp.discount_amount}</td>
                                            <td align="right" >{sendResp.paid_amount}</td>
                                            <td align="right" >{sendResp.balance_amount}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" align="center" ><b>Total</b></td>
                                            <td align="right" >1</td>
                                            <td align="right" >{sendResp.basic_amount}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.total_bill_amount}</td>
                                            <td align="right" >{sendResp.discount_amount}</td>
                                            <td align="right" >{sendResp.paid_amount}</td>
                                            <td align="right" >{sendResp.balance_amount}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" style={{ border: 'none' }}>{sendResp.amount_in_words} Only /-</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8" valign="bottom" >
                                                <Table  width="564" cellPadding="1" cellSpacing="1">
                                                    <tr>
                                                        <td style={{ border: 'none' }}>E. &amp;. O. E.</td>
                                                        <td style={{ border: 'none' }} align="right">Customer Signature</td>
                                                    </tr>
                                                </Table>
                                            </td>
                                            <td colSpan="5" valign="top" >
                                                <Table  width="450" border="0" cellPadding="1" cellSpacing="1">
                                                    <tr>
                                                        <td align="left" valign="top" style={{ border: 'none' }}>For <b>{sendResp1.spa_name}</b> Unit of Ganpati Arcade</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: 'none' }} align="right" valign="bottom">Authorised Signatory</td>
                                                    </tr>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Table>
                                </td>
                            </tr>
                        </Table>
                    </div>
                    <div className="invoice-divider">
                        <LuScissorsLineDashed />
                        <div className="dashed-line"></div>
                        <LuScissorsLineDashed style={{ transform: 'rotate(180deg)' }} />
                    </div>
                    {showButton && (
                    <div className="invoice-button text-center">
                        <button onClick={handlePrint} className='bt-invoice '>print invoice</button>
                    </div>
                )}

                    <div className="invoice-section table-head" >
                        <Table border="0" cellSpacing="1" cellPadding="1" >
                            <tr className='invoice-change'>
                                <td valign="top" className="no-padding invoice-change">
                                    <div className=' d-flex  justify-content-between align-item-center'>
                                            <p >GSTIN</p>
                                            <p>{sendResp1.gst_no}</p>
                                            <div className=' text-left'>
                                                <p className="invoice-title-1">INVOICE</p>
                                            </div>
                                            <div >
                                                (Duplicate)
                                                <p>PH : {sendResp1.branch_contact_no}</p>
                                                <p>Mob : {sendResp1.branch_mobile_no}</p>
                                            </div>
                                            </div>

                                    <div className=' text-center' >

                                                <h5>{sendResp1.spa_name}</h5>
                                                <p>(UNIT OF {sendResp1.company_name})</p>
                                                <h6>{sendResp1.branch_address}</h6>

                                    </div>
                                </td>
                            </tr>
                        </Table>

                        <Table  border="0" cellSpacing="1" cellPadding="1">
                            <tr>
                                <td>
                                    <Table border="0" cellSpacing="1" cellPadding="1">
                                        <tr className='invoice-change'>
                                            <td>NAME</td>
                                            <td>{sendResp.cust_name}</td>
                                            <td>INVOICE NO.</td>
                                            <td>{userId}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>ADDRESS</td>
                                            <td>{sendResp.cust_address}</td>
                                            <td>BILL DATE</td>
                                            <td>{ formatDate (sendResp?.booking_date?.date.split(' ')[0])}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>CONTACT NO.</td>
                                            <td>{sendResp.cust_contact}</td>
                                            <td>PAYMENT TYPE</td>
                                            <td>{sendResp.payment_type}</td>
                                        </tr>
                                        <tr className='invoice-change'>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>SERVICE TYPE</td>
                                            <td>{sendResp.service_type}</td>
                                        </tr>
                                    </Table>
                                </td>
                            </tr>
                        </Table>

                        <Table  border="0" cellSpacing="1" cellPadding="1">
                            <tr className="invoice-change">
                                <td valign="top" className='invoice-table-main '>
                                    <Table  className="invoice-table" cellPadding="1" cellSpacing="1">
                                        <tr className="invoice-table-header invoice-change">
                                            <td rowSpan="2">Sno.</td>
                                            <td rowSpan="2">Description</td>
                                            <td rowSpan="2">HSN/SAC</td>
                                            <td rowSpan="2">Qty.</td>
                                            <td rowSpan="2">Price</td>
                                            <td >SGST</td>
                                            <td>CGST</td>
                                            <td rowSpan="2">Total</td>
                                            <td rowSpan="2">Discount</td>
                                            <td rowSpan="2">Received Amount</td>
                                            <td rowSpan="2">Outstanding Amount</td>
                                            <td>SGST.</td>
                                            <td>CGST.</td>
                                        </tr>
                                        <tr className="invoice-table-header invoice-change">
                                            <td align="center" >9%</td>
                                            <td align="center">9%</td>
                                            <td align="center" >9%</td>
                                            <td align="center">9%</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Oil Massage</td>
                                            <td align="right" >0</td>
                                            <td align="right" >1</td>
                                            <td align="right" >{sendResp.basic_amount}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.total_bill_amount}</td>
                                            <td align="right" >{sendResp.discount_amount}</td>
                                            <td align="right" >{sendResp.paid_amount}</td>
                                            <td align="right" >{sendResp.balance_amount}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" align="center" ><b>Total</b></td>
                                            <td align="right" >1</td>
                                            <td align="right" >{sendResp.basic_amount}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.gst_amount / 2}</td>
                                            <td align="right" >{sendResp.total_bill_amount}</td>
                                            <td align="right" >{sendResp.discount_amount}</td>
                                            <td align="right" >{sendResp.paid_amount}</td>
                                            <td align="right" >{sendResp.balance_amount}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                            <td align="right" >{sendResp.after_discount_tax / 2}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" style={{ border: 'none' }}>{sendResp.amount_in_words} Only /-</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8" valign="bottom" >
                                                <Table  width="564" cellPadding="1" cellSpacing="1">
                                                    <tr>
                                                        <td style={{ border: 'none' }}>E. &amp;. O. E.</td>
                                                        <td style={{ border: 'none' }} align="right">Customer Signature</td>
                                                    </tr>
                                                </Table>
                                            </td>
                                            <td colSpan="5" valign="top" >
                                                <Table  width="450" border="0" cellPadding="1" cellSpacing="1">
                                                    <tr>
                                                        <td align="left" valign="top" style={{ border: 'none' }}>For <b>{sendResp1.spa_name}</b> Unit of Ganpati Arcade</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: 'none' }} align="right" valign="bottom">Authorised Signatory</td>
                                                    </tr>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Table>
                                </td>
                            </tr>
                        </Table>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Invoice