import React, { useEffect, useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import './membershipview.css'
import { FaChevronDown } from 'react-icons/fa';
import { Tipaccountadmin } from './Tipacountadmin';
import { lineSpinner } from 'ldrs'
import { formatDate, maskContact } from '../customfunction/dateformatmonth';
import { Spinner } from 'react-bootstrap';
import UpdateService from './UpdateService';
import { encryptData } from '../cryptoutils/cryptoutils';
import { Link } from 'react-router-dom';
lineSpinner.register()




const ModuleComplentary = () => {
  const [report, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const[loading,setloading]=useState(false)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setloading(true)
    const fetchMembershipData = async () => {
        const obj = {
          query: `[dbo].[spasoftware_web_proc_module_view_walking_complentary_getdata]`,
          queryArr:[]
        }
        const getData = await Hexapi(obj);
        setData(getData.complentarydata || []);
        setloading(false)
        console.log(getData, "Complentary");
      }
    fetchMembershipData();
  }, []);

  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{formatDate(row.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
    { Header: "Branch Name", accessor: "branch_name" },
    {
      Header: "Walking Id",
      accessor: "member_id",
      Cell: ({ row }) => (
        <Link
        className=" curpoint"
        to={`/updatecreatedata/${ encodeURIComponent(encryptData(row.original.user_id))}`} rel="noopener noreferrer" target="_blank">
          {row.original.member_id}
        </Link>
      )
    },
    { Header: "Customer Name", accessor: "cust_name" },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Create By", accessor: "create_by" },

    {
      Header: "Tip",
      accessor: "tip",
      Cell: ({ row }) => (
        <Link to={`/tipaccountadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Tip</Link>
      )
    }

  ], []);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
        <div className='container mt-1 mb-1'>
          <div className='row bg-body-tertiary'>
            <div className='dashservice'>
              <p className='name-text'>Walking Complentary Accounts</p>
              <FaChevronDown/>
            </div>
            <div>
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 25, 50, 75, 100].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={globalFilter || ""}
                      onChange={e => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {loading ? (
                          <tr>
                          <td colSpan={17} className="text-center">
                          <Spinner animation="border" />
                        </td>
                           </tr>
                        ) : report.length === 0 ? (
                          <tr>
                            <td className="fs-6" colSpan={17}>No Data Available</td>
                          </tr>
                        ) : (
                          page.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                ))}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                  <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
          </div>
        </div>
    </>
  )
}
export default ModuleComplentary;
