import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { FaChevronDown } from "react-icons/fa";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { lineSpinner } from 'ldrs';
import { formatDate, maskContact } from '../customfunction/dateformatmonth';
import UpdateService from "./UpdateService";
import Renewadmin from "./Renewadmin";
import Select from 'react-select';
import {Tipaccountadmin} from "./Tipacountadmin";
import Serviceadmin from "./Serviceadmin";
import Paidadmin from "./Paidadmin";
import { decryptData, encryptData } from "../cryptoutils/cryptoutils";
import { Link, useParams } from "react-router-dom";
lineSpinner.register();

const ModuleMembership = () => {
  const {branchname}=useParams()
  // console.log(branchname,"----------branchname")
  const branchdatacheck=branchname
  useEffect(()=>{
window.scrollTo(0,0)
  },[])


  useEffect(()=>{
    if(branchdatacheck !==undefined){
      const branchdata=decryptData(branchname)
      const handlebranch = async () => {
        setLoading(true);
        const obj = {
          query: `[dbo].[spasoftware_web_proc_module_view_membership_account]@branchname='{0}'`,
          queryArr: [`${branchdata}`]
        };
        const getdata = await Hexapi(obj);
        setReport(getdata[""] || []);
        setLoading(false);
      };
      handlebranch()
    }

  },[branchdatacheck])
  const [isFormVisible, setFormVisible] = useState(true);
  const [data, setData] = useState('');
  console.log(data,"==============")
  const [report, setReport] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [branchnamedata, setBranchnamedata] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);



  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  useEffect(() => {
    const fetchBranchNames = async () => {
      const obj = {
        query: `[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr: []
      };
      const getdata = await Hexapi(obj);
      console.log(getdata,"getdata")
      setBranchnamedata(getdata.branch || []);
    };
    fetchBranchNames();
  }, [])

  const handleCancel = async (cancelid,branch) => {
    console.log(branch,"data")
    // const obj = {
    //   query: `[dbo].[spa_software_web_proc_module_membership_account]@cancelid='{0}'`,
    //   queryArr: [`${cancelid}`]
    // };
    // const cancelData = await Hexapi(obj);
    // window.scrollTo(0, 0);
    // toast.success(`${cancelData[""][0].msg}`);npm
    try {
      setLoading(true);
      const obj = {
        query: `[dbo].[spasoftware_web_proc_module_view_membership_account]@branchname='{0}'`,
        queryArr: [`${branch}`]
      };
      const getdata = await Hexapi(obj);
      setReport(getdata[""] || []);
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  }
  // const handleRenew = (user_id, memberid, cust_name, cust_contact) => {
  //   setSelectedMemberId(memberid);
  //   setUserid(user_id);
  //   setCustomerName(cust_name);
  //   setCust_contact(cust_contact);
  //   setRenewForm(true);
  // }

  const handleBranchChange = useCallback((selectedOption) => {
    setData(selectedOption ? selectedOption.value : '');
  }, []);

  const handleReport = useCallback(async () => {
    try {
      setLoading(true);
      const obj = {
        query: `[dbo].[spasoftware_web_proc_module_view_membership_account]@branchname='{0}'`,
        queryArr: [`${data}`]
      };
      const getdata = await Hexapi(obj);
      setReport(getdata[""] || []);
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  }, [data]);

    const branchOptions = branchnamedata.map(br => ({
      value: br.branchname,
      label: br.branchname
    }));

  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{formatDate(row.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
    { Header: "Branch Name", accessor: "branch_name" },
    {
      Header: "Member Id",
      accessor: "member_id",
      Cell: ({ row }) => (
        <Link
          className=" curpoint"
          to={`/updatecreatedata/${ encryptData(row.original.user_id)}`}
        >
          {row.original.member_id}
        </Link>
      )
    },
    { Header: "Customer Name", accessor: "cust_name" },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Create By", accessor: "create_by" },
    {
      Header: "Booking",
      accessor: "booking",
      Cell: ({ row }) => (
        <>
      <Link to={`/viewserviceadmin/${ encryptData(row.original.user_id)}/${encryptData(row.original.member_id)}`} className="btn-click-1" rel="noopener noreferrer" target="_blank">service</Link>
        {/* { row.original.cust_bal_service===0 ? <Link className="btn-click-2" onClick={()=>{handleRenew(row.original.user_id,
          row.original.member_id,
          row.original.cust_name,
          row.original.cust_contact,
          row.original.cust_paid_amount,
          row.original.branch_name)}}>Renew</Link>: null} */}
        { row.original.cust_used_service===0 ? <Link onClick={()=>{ handleCancel(row.original.user_id,row.original.branch_name)}} className="btn-click-3" rel="noopener noreferrer" target="_blank">cancel</Link>: null}
        </>
      )
    },
    {
      Header: "Tip",
      accessor: "tip",
      Cell: ({ row }) => (
        <Link to={`/tipaccountadmin/${ encryptData(row.original.user_id)}/${ encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Tip</Link>
      )
    },
    {
      Header: "Pending",
      accessor: "pending",
      Cell: ({ row }) => (
        <Link to={`/paidaccountadmin/${ encryptData(row.original.user_id)}/${ encryptData(row.original.member_id)}`} className='btn-click-1' rel="noopener noreferrer" target="_blank">Paid</Link>
      )
    }
  ], [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
        <>
          <div className="quick_search_page">
            <div className="widget-title">
              <p className="name-text">Search Report</p>
              <span>

                  <FaChevronDown    onClick={toggleFormVisibility}/>

              </span>
            </div>
          </div>
          {isFormVisible && (
            <div className="widget-body form">
              <div name="dname" id="dname1" className="form-horizontal">
                <div className="control-group-div">
                  <div className="control-group">
                    <label className="control-label">Select SPA Branch</label>
                    <div className="controls">
                      <Select
                        className="span6"
                        id="therapist_weekly_off"
                        name="data"
                        options={branchOptions}
                        value={branchOptions.find(option => option.value === data) }
                        onChange={handleBranchChange}
                        isClearable
                        classNamePrefix="react-select"
                        placeholder="Select Branch Name"
                      />
                    </div>
                  </div>
                  <div className="form-actions-control">
                    <button
                      type="button"
                      name="ShowInfoBtn"
                      className="btn btn-success"
                      id="show_btn"
                      onClick={handleReport}
                    >
                      Show Report
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          )}
          <div className="container mb-5">
            <div className="row bg-body-tertiary mt-4">
              <div className="dashservice">
                <p className="name-text">Membership Accounts : <span className="text-danger">Branch : {data}</span></p>
                <FaChevronDown onClick={() => setShowTable(!showTable)} />
              </div>
              {showTable && (
                <div>
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 25, 50, 75, 100].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={globalFilter || ""}
                      onChange={e => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {loading ? (
                        <tr>
                       <td colSpan={17} className="text-center">
                       <Spinner animation="border" />
                     </td>
                        </tr>
                      ) : report.length === 0 ? (
                        <tr>
                          <td className="fs-6" colSpan={17}>No Data Available</td>
                        </tr>
                      ) : (
                        page.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              ))}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                  <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>


  );
};

export default ModuleMembership;
