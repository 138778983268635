import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import { IoIosArrowBack } from "react-icons/io";
import { MdEmail, MdMarkEmailRead } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import { BiSolidNotepad } from "react-icons/bi";
import { PiCalendarStarFill } from "react-icons/pi";
import { BiSolidCalendarCheck } from "react-icons/bi";
import { VscFileSubmodule } from "react-icons/vsc";
import { FaBell, FaUsersViewfinder } from "react-icons/fa6";
import { IoBagAddSharp } from "react-icons/io5";
import { MdAccountBox } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { FaSpa } from "react-icons/fa6";
import { SiCoinmarketcap } from "react-icons/si";
import { MdLibraryBooks } from "react-icons/md";
import { FaDailymotion } from "react-icons/fa6";
import { FaBackspace } from 'react-icons/fa';
import { IoLogOutSharp } from "react-icons/io5";

import { MdCardMembership } from "react-icons/md";
import './Navbar.css'
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import Impspa from '../images/logo.png'
import { Link } from 'react-router-dom';

const Hamburger = ({show,handleoff}) => {


    const handlelogout=()=>{
        localStorage.removeItem('Apipathurl')
        window.location.href="https://bagero.hexbss.xyz/login"
      }
  return (
    <div>
    <Offcanvas show={show} className="">
    <Offcanvas.Header className="back-new">
          <Offcanvas.Title >
            <img src={Impspa} alt="" />
          </Offcanvas.Title>
          <Offcanvas.Title>
          <IoIosArrowBack  style={{ fontSize: "20px" }} className="text-g"    onClick={() => {
              handleoff();
            }}/>
          </Offcanvas.Title>
        </Offcanvas.Header>
    <Offcanvas.Body className='off-body'>
    <div className='itemin'>
    <ul>
                 <li>
                    <Link to="" className='dis-icon-1 '>
                            <p className='icon-size' ><MdMarkEmailRead  className='icon-dis-new'/></p>
                        <p className='dis-p'>Dashboard</p>
                            </Link>
                        </li>
                <li>
                    <Link to="quicksearch" className='dis-icon-1 '>
                     <p className='icon-size' ><IoSearch  className='icon-dis-new'/></p>
                <p className='text-font'>Quick Search</p>
                    </Link>
                </li>
                <li>
                    <Link to="attendance" className='dis-icon-1 '>
                     <p className='icon-size' ><BiSolidNotepad   className='icon-dis-new'/></p>
                <p className='text-font'>Attendance</p>
                    </Link>
                </li>
                <li>
                <Link to="appointment" className='dis-icon-1 '>
                    {/*  <p className='icon-size' >< FcCalendar className='icon-dis-new'/></p> */}
                     <p className='icon-size' ><BiSolidCalendarCheck  className='icon-dis-new'/></p>
                <p className='text-font'>Appointment</p>
                    </Link>
                </li>
                <li>
                <Link to="priorappointment" className='dis-icon-1 '>
                     <p className='icon-size' ><PiCalendarStarFill  className='icon-dis-new'/></p>
                <p className='text-font'>Prior Appointment</p>
                    </Link>
                </li>

                <li>
                <Link to="addtherapist" className='dis-icon-1 '>
                     <p className='icon-size' ><IoBagAddSharp  className='icon-dis-new'/></p>
                    {/*  <p className='icon-size' ><BsPersonFillAdd   className='icon-dis-new'/></p> */}
                <p className='text-font'>Add Therapist</p>
                    </Link>
                </li>
                <li>
                <Link to="" className='dis-icon-1 '>
                    <p className='icon-size'><MdEmail className='icon-dis-new'/></p>
                <p className='text-font'>Therapist Information</p>
                    </Link>
                </li>
                <li>
                <Link to="" className='dis-icon-1 '>
                    <p className='icon-size'><FaBell className='icon-dis-new'/></p>
                <p className='text-font'>Prior Appointment</p>
                    </Link>
                </li>
                <li>
                <Link to="" className='dis-icon-1 '>
                    <p className='icon-size'><FaBell className='icon-dis-new'/></p>
                <p className='text-font'>Today followup</p>
                    </Link>
                </li>
                <li>
                <Link to="mobileverification" className='dis-icon-1 '>
                    <p className='icon-size'><MdOutlineMobileFriendly className='icon-dis-new'/></p>
                <p className='text-font'>Mobile Verification</p>
                    </Link>
                </li>
            </ul>
           <div>
           <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="1" className=''>
        <Link to='createaccount' >
            <Accordion.Header className=' text-white'><p className='icon-size'> <MdAccountBox  className='icon-dis-new'/></p> <span className='  text-font mar-gin'>Create Account</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul>
                    <li>
                    Create Account
                    </li>
                </ul>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" >
          <Link to='viewaccount' >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <FaUsersViewfinder className='icon-dis-new'/></p> <span className=' text-font mar-gin'>View Account</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul className='ultype'>
                <li>Membership Account</li>
             <li>Walkin Account</li>
             <li>Complentary Account</li>
                </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
          <Link to='createaccount' >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <FaSpa className='icon-dis-new'/> </p><span className=' text-font mar-gin'>SPA Master</span></Accordion.Header></Link>
            <Accordion.Body>
            <ul className='ultype'>
                <li>All Membership Account</li>
             <li> Therapist Report</li>
                </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
          <Link to='createaccount' >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <SiCoinmarketcap className='icon-dis-new'/> </p><span className=' text-font mar-gin'>SPA Marketing</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul>
                    <li>
                    Search by Balance
                    </li>
                </ul>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
          <Link to='createaccount' >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <TbReport className='icon-dis-new'/> </p><span className=' text-font mar-gin'>Report Master</span></Accordion.Header></Link>
            <Accordion.Body>
             <ul>
                <li>Sale Report</li>
                <li>Sale Report Format 2</li>
                <li>Total Sale Report</li>
                <li>Nearby Sale Report</li>
                <li>Membership Ref Report</li>
                <li>Daily Service Book</li>
             </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
          <Link to='createaccount' >
            <Accordion.Header className=' text-white'><p className='icon-size'>  <VscFileSubmodule className='icon-dis-new'/></p> <span className=' mar-gin'>Modules</span></Accordion.Header></Link>
            <Accordion.Body>
             <ul>
                <li>View Membership A/C</li>
                <li>View Walkin A/C</li>
                <li>View Complentary A/C</li>
             </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className='itemin '>
            <ul>

                <li>
                <Link to="dailysalebook" className='dis-icon-1 '>
                    {/*  <p className='icon-size' ><FaDailymotion  className='icon-dis-new'/></p> */}
                     <p className='icon-size' ><MdLibraryBooks className='icon-dis-new'/></p>
                <p className='text-font'>Daily Sale Book</p>
                    </Link>
                </li>
                <li>
                <Link to="membership" className='dis-icon-1 '>
                     <p className='icon-size' ><MdCardMembership  className='icon-dis-new'/></p>
                <p className='text-font'>Membership Ref.</p>
                    </Link>
                </li>
                <li>
                <Link to="logout" className='dis-icon-1 '>
                     <p className='icon-size' ><IoLogOutSharp  className='icon-dis-new'/></p>
                <p className='text-font' onClick={handlelogout}>Logout</p>
                    </Link>
                </li>
            </ul>
           <div>
           </div>
           </div>
           </div>
           </div>
       </Offcanvas.Body>
 </Offcanvas>
    </div>
  )
}
export default Hamburger