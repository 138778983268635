import React, { useEffect, useState } from 'react'
import profileimg from '../img/img/profile-pic.jpg'
import { useSelector } from 'react-redux'
import { FaChevronDown, FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';


const Profile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const selector=useSelector(state=>state)
  const [managername,setmanagername]=useState(null)
  const [role,setrole]=useState(null)
  const [email,setemail]=useState(null)
  const [contact,setcontact]=useState(null)
  const [branch,setbranch]=useState(null)
  const [userid,setuserid]=useState(null)
  const [spa,setspa]=useState(null)
  const [datee,setdatee]=useState(null)
  const[show,setshow]=useState(true)

  useEffect(()=>{
  if(selector.details.data !=null){
 const manager=selector.details.data[""][0].manager_name
 const managerrole=selector.details.data[""][0].user_role
 const manageremail=selector.details.data[""][0].manager_email
 const managercontact=selector.details.data[""][0].manager_number
 const managerspabranch=selector.details.data[""][0]. spa_branch_name
 const useridset=selector.details.data[""][0].manager_userid
 const spaname=selector.details.data[""][0].spa_name
 const cuurrdate=selector.details.data[""][0].currdate.date.split(' ')[0]


 setmanagername(manager)
 setrole(managerrole)
 setcontact(managercontact)
 setemail(manageremail)
 setbranch(managerspabranch)
 setuserid(useridset)
 setdatee(cuurrdate)
 setspa(spaname)
//  console.log(data,"data")
  }
  },[selector,managername])
//   console.log(data.manager_name)
  return (
    <div className='container mb-5'>
 <div className='row'>
 <div className='dashservice'>
                <p className='name-text'>Profile</p>
                <FaChevronDown  onClick={()=>{setshow(!show)}}/>
            </div>
 </div>
    { show &&     <div className='row bg-body-tertiary p-3'>

<div className='col-lg-3'>
    <div>
       <img src={profileimg}/>
    </div>
</div>
<div className='col-lg-6'>
    <div>
    <div>

    <h6><b>{managername}</b>
         <br/><small>{role}</small>
         </h6>

         <div style={{marginTop:"30px"}}>
         <Table>
                        <tbody>
                        <tr>
                            <td class="span2">Full Name :</td>
                            <td>
                               {managername}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2">Contact No :</td>
                            <td>
                              {contact}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2">Email Id :</td>
                            <td>
                            {email}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2">User Id :</td>
                            <td>
                                 {userid}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2">Spa Name :</td>
                            <td>
                                {spa}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2"> Branch Name :</td>
                            <td>
                               {branch}
                            </td>
                        </tr>
                        <tr>
                            <td class="span2"> DOJ Date :</td>
                            <td>
                                {datee}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
         </div>
    </div>
    </div>
</div>
<div className='col-lg-3'>
<div>
                    <h6>SPA's</h6>
                    <ul className=' list-unstyled'>
                        <li className=' d-flex gap-2'>
                        <p><FaRegHandPointRight /></p>
                        <div>
                        <strong>AURA DAY SPA</strong><br/>
										<em>AURA DAY SPA - MGF - GURGAON</em><br/>
										<em>AURA DAY SPA - DT - GURGAON</em><br/>
										<em>AURA DAY SPA - SPM - GURGAON</em><br/>
										<em>AURA DAY SPA - RAJOURI GARDEN - DELHI</em><br/>
										<em>AURA DAY SPA - GREEN PARK - DELHI</em><br/>
										<em>AURA DAY SPA - SAKET - DELHI</em><br/>
                            <Link className="text-success" to='/www.auradayspa.in'>www.auradayspa.in</Link>
                            </div>
                        </li>

                        <li className=' d-flex gap-2'>
                        <p><FaRegHandPointRight /></p>
                        <div>
                            <strong>SOBO SPA</strong><br/>
                            SOBO SPA - JMD - GURGAON<br/>
                            SOBO SPA - SAKET - DELHI<br/>
                            <Link className="text-success" to='www.sobospa.in'>www.sobospa.in</Link>
                            </div>
                        </li>

                        <li className=' d-flex gap-2'>
                        <p><FaRegHandPointRight /></p>
                        <div>
                            <strong>AANANDAM SPA</strong><br/>
                            AANANDAM SPA - MGF - GURGAON<br/>
                            <Link className="text-success" to='www.aanandamspa.com'>www.aanandamspa.com</Link>
                            </div>
                        </li>

                        <li className=' d-flex gap-2'>
                        <p><FaRegHandPointRight /></p>
                        <div>
                            <strong>SPA EVANTHE</strong><br/>
                            SPA EVANTHE - MGF - GURGAON<br/>
                            <Link className="text-success" to='www.spaevanthe.in'>www.spaevanthe.in</Link>
                            </div>
                        </li>
                    </ul>

                </div>
</div>
</div>}
    </div>
  )
}

export default Profile