import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import { useSelector } from "react-redux";
import { lineSpinner } from 'ldrs';
import { default as Select } from 'react-select';
import toast from "react-hot-toast";
import { decryptData, encryptData } from "../cryptoutils/cryptoutils";
import { useNavigate, useParams } from "react-router-dom";
lineSpinner.register();

const Renew = () => {
  const{userid,memberid}=useParams()
  const userId=decryptData(userid)
  const memberId=decryptData(memberid)
  const navigate=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const selector= useSelector(state=>state)
const[loading,setloading]=useState(false)
const[servicehourdata,setservicehour]=useState([])
const[submitting,setSubmitting]=useState(false)

    const [data, setdata] = useState({
    dop_date: "",
    customer_name: "",
    customer_address: "",
    customer_no:"",
    customer_signature: "",
    customer_service:"",
    reference_name:"",
    payment_mode:"",
    basic_amount:"",
    tax_amount:"",
    total_bill_amount:"",
    paid_amount:"",
    amount_in_word:"",
    balance_account:"",
    discount_account:"",
    basic_amount_after_discount:"",
    after_discount_tax:"",
    total_amount:"",
    total_service_hour:"",
    branchname:"",
    spaname:"",
    createby:""
  })
 const handleclear=()=>{
  setdata({
    dop_date: "",
    customer_name: "",
    customer_address: "",
    customer_no: "",
    customer_signature: "",
    customer_service:"",
    reference_name:"",
    payment_mode:"",
    basic_amount:"",
    tax_amount:"",
    total_bill_amount:"",
    paid_amount:"",
    amount_in_word:"",
    balance_account:"",
    discount_account:"",
    basic_amount_after_discount:"",
    after_discount_tax:"",
    total_amount:"",
    total_service_hour:"",
    branchname:"",
    spaname:"",
    createby:""
  })
 }
  const[show,setshow]=useState(true)
  const[paydata,setpaydata]=useState([])
  const[formdataget,setformdataget]=useState([])


  const selectformgetdata=async()=>{
    if(selector.details.data !== null){
      const branchname=selector.details.data && selector.details.data[""][0].spa_branch_name
      const objdata= {
        query:`[dbo].[spasoftware_web_proc_renew_formgetdata]@branchname='{0}',@userid='{1}'`,
        queryArr:[`${branchname}`,`${userId}`]
     }
     try {
        const obj= await Hexapi(objdata)
        console.log(obj.renewdata[0],"objget")
        setformdataget(obj.refname)
        setservicehour(obj.servicehour)
        setdata((prev)=>({
          ...prev,
          customer_name:obj.renewdata[0].cust_name,
          customer_no:obj.renewdata[0].cust_contact
        }))
     } catch (error) {
    console.log("error")
     }
    }

  }
  const handlechangedate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US",{
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    setdata((prev) => ({
      ...prev,
      dop_date: formattedDate,
    }));
  };

  const handlebasic_amount=(e)=>{
    const basicamount=e.target.value
    const basic=parseInt(basicamount)
    const taxdata=parseInt((basic*18)/100)
    const totalbillamunt= basic+taxdata
    setdata((prev)=>({
  ...prev,
  basic_amount:basic,
  tax_amount:taxdata,
  total_bill_amount:totalbillamunt
  })
  )}
  const handlepaid_amount_change = (e) => {
    const paiddata = e.target.value;


    if (!isNaN(paiddata)) {
        const paidvalue = parseInt(paiddata);
        const wordsamount = convertNumberToWords(paidvalue);
        const balanceamount= data.total_bill_amount-paidvalue

        setdata((prev) => ({
            ...prev,
            paid_amount:paidvalue,
            amount_in_word: wordsamount,
            balance_account:balanceamount
        }));

    } else {
        console.log('Invalid input: Please enter a valid number.');
    }

    if (isNaN(paiddata)) {
      setdata((prev) => ({
        ...prev,
        paid_amount: "",
        amount_in_word: "",
        balance_account: ""
      }))}
};
function convertNumberToWords(amount) {
  let words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  let atemp = amount.split(".");
  let number = atemp[0].split(",").join("");
  let n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
          received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
          n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              if (n_array[i] == 1) {
                  n_array[j] = 10 + parseInt(n_array[j]);
                  n_array[i] = 0;
              }
          }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              value = n_array[i] * 10;
          } else {
              value = n_array[i];
          }
          if (value != 0) {
              words_string += words[value] + " ";
          }
          if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Crores ";
          }
          if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Lakhs ";
          }
          if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Thousand ";
          }
          if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
              words_string += "Hundred and ";
          } else if (i == 6 && value != 0) {
              words_string += "Hundred ";
          }
      }
      words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}



let newdiscount=0
const handlediscount=(e)=>{
  const discountdata= e.target.value
  const disdata=parseInt(discountdata)
  console.log(disdata,"disdata")
  const afterdiscount=Math.round((data.paid_amount*18)/118)
  console.log(data.balance_account,"amount")

  newdiscount=disdata
  setdata((prev)=>({
    ...prev,
    discount_account:disdata,
    balance_account:data.total_bill_amount-data.paid_amount-disdata,
    after_discount_tax:afterdiscount,
    basic_amount_after_discount:data.paid_amount-afterdiscount,
    total_amount:data.total_bill_amount-disdata,
    spaname:selector.details.data && selector.details.data[""][0].spa_name,
    branchname:selector.details.data && selector.details.data[""][0].spa_branch_name,
    createby:selector.details.data && selector.details.data[""][0].manager_name
  }))
}

const handle_paydata=async()=>{

  const obj={
   query:`[dbo].[spasoftware_web_proc_createservice_servicefeild_onchange]@value='{0}'`,
   queryArr:["membership"]
  }
  try {
   const datapost= await Hexapi(obj)
   setpaydata(datapost.paymentmode
   )
  console.log(datapost,"datapost")
  } catch (error) {
   console.log("error")
  }
}

  useEffect(()=>{
     handlechangedate()
     handle_paydata()
  },[])

  useEffect(()=>{
    selectformgetdata()
  },[selector,userId])

  const handlechange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setdata({ ...data, [name]: value });
    console.log(data,"data")
    console.log(e,"e")
  }

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    if (data.reference_name === "" || data.payment_mode === "" || data.customer_signature === "") {
      window.scrollTo(0, 0);
      toast.error("Please fill all the fields");
      setloading(false);
      return;
    }
    const obj = {
        query: `[dbo].[spasoftware_web_proc_Renew_createnewserviceuser_insertdata]@dop_date='{0}',@customer_name='{1}',@customer_address='{2}',@customer_no='{3}',@customer_signature='{4}',@customer_service='{5}',@payment_mode='{6}', @reference_name='{7}',@basic_amount='{8}',@tax_amount='{9}',@total_bill_amount='{10}',@paid_amount='{11}',@word='{12}',@balance_amount='{13}',@discount_amount='{14}',@basic_amount_after_discount='{15}',@after_discount_tax='{16}',@total_amount='{17}',@total_service_hour='{18}',@branchname='{19}',@spa_name='{20}',@create_by='{21}',@memberid='{22}'`,
        queryArr: [
            `${data.dop_date}`, `${data.customer_name}`, `${data.customer_address}`, `${data.customer_no}`, `${data.customer_signature}`, `${data.customer_service}`, `${data.payment_mode}`, `${data.reference_name}`, `${data.basic_amount}`, `${data.tax_amount}`, `${data.total_bill_amount}`, `${data.paid_amount}`, `${data.amount_in_word}`,
            `${data.balance_account}`, `${data.discount_account}`, `${data.basic_amount_after_discount}`, `${data.after_discount_tax}`, `${data.total_amount}`, `${data.total_service_hour}`, `${data.branchname}`, `${data.spaname}`, `${data.createby}`,`${memberId}`
        ]
    }
  const resp = await Hexapi(obj);
  console.log(resp)
  setSubmitting(false)
  navigate(`/viewservice/${encodeURIComponent(encryptData(userId))}/${encodeURIComponent( encryptData(memberId))}`)
}

const treactmentoption = servicehourdata.map(hr => ({
  value: hr.servicehour,
  label: hr.service_hour
}))
const handletreatmentChange = (selectedOption) => {
  setdata({ ...data, total_service_hour: selectedOption ? selectedOption.value : '' });
};


const payment_modeoption = paydata?.map(hr => ({
  value: hr.paymentmode,
  label: hr.paymentmode
}));
const handlepayment_modeChange = (selectedOption) => {
  setdata({ ...data, payment_mode: selectedOption ? selectedOption.value : '' });
};


const therapistOptions = formdataget.map(therapist => ({
  value: therapist.refname,
  label: therapist.refname
}));
const managername = selector.details.data && selector.details.data[""][0].manager_name;
therapistOptions.push({
  value: managername,
  label: managername
});

const handleTherapistChange = (selectedOption) => {
  setdata({ ...data, reference_name: selectedOption ? selectedOption.value : '' });
};

const service_option = [

  { value: 'membership', label: 'Membership Service' }
]

const signature_option = [

  { value: '1', label: 'yes' },
  { value: '0', label: 'no' }
]
const handlesignature_option = (selectedOption) => {
  setdata({ ...data, customer_signature: selectedOption ? selectedOption.value : '' });
};
const handleserviceChange = (selectedOption) => {
  setdata({ ...data, customer_service: selectedOption ? selectedOption.value : '' });
};
return (
  <div>
  { loading ? <div className="text-center mt-5"><l-line-spinner
   size="40"
   stroke="3"
   speed="1"
   color="black"
   className="relative"

 ></l-line-spinner></div> :<div className="container top-acc highlightBcg">
 <div className="row bg-body-tertiary ">
     <div className="dashservice">
       <p className="name-text">
      RENEW SERVICE
       </p>

         <FaChevronDown onClick={()=>{setshow(!show)}} />

     </div>
     </div>

   <div className="row bg-body-tertiary">
   {show && (
               <form onSubmit={handlesubmit} className="form-data">

                 <div className="controls">
                 <div className="control-group">
           <label className="control-label">
           Renew Membership Id
           </label>
           <div className="controls">
             <input
               type="text"
                className="span7  popovers"
               name="memberId"
               id="customer_name"
               value={memberId}
               disabled
             />
           </div>
         </div>
         <div className="control-group">
         <label className="control-label">
             DOP Date<a className="blink">*</a></label>
           <div className="blinkTextDiv controls">
             <input
               id="dop_date"
               type="text"
               name="dop_date"
               value={data.dop_date}
               disabled
               className="span7  popovers"

             />

           <a className="blink" id="blinkText">
               *You can't change date : Contact Us
             </a>
             </div>
         </div>
         <div className="control-group">
           <label className="control-label">
             Customer Name<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="text"
                className="span7  popovers"
               name="customer_name"
               value={data.customer_name}
               id="customer_name"
               disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Customer Address<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="text"
                className="span6  popovers"
               name="customer_address"
               id="customer_address"
               value={data.customer_address}
              onChange={handlechange}
             />
           </div>
         </div>
         <div className="control-group">
         <label className="control-label">
             Customer Number<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               className="span7  popovers"
               type="number"
               value={data.customer_no}
               name="customer_no"
               id="customer_no"
             disabled

             />
           </div>
         </div>
         <div className="control-group">
         <label className="control-label">
             Customer Form Signature<a className="blink">*</a>
           </label>
           <div className="blinkTextDiv controls">
           <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="customer_service"
                      options={signature_option}
                      value={service_option.find(option => option.value === data.customer_signature)}
                      onChange={ handlesignature_option}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Customer Signature"
                    />


           <a className="blink" id="blinkText">
               *Ignore This
             </a>
             </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Customer Service<a className="blink">*</a>
           </label>
           <div className="controls">
           <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="customer_service"
                      options={service_option}
                      value={service_option.find(option => option.value === data.customer_service)}
                      onChange={handleserviceChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Customer Service"
                    />
           </div>
         </div>

<div className="control-group">
 <label className="control-label">
   Reference Name<a className="blink">*</a></label>
 <div className="controls">
 <Select
                          className="span6"
                          id="therapist_weekly_off"
                          name="reference_name"
                          options={therapistOptions}
                          value={therapistOptions.find(option => option.value === data.reference_name)}
                          onChange={handleTherapistChange}
                          isClearable
                          classNamePrefix="react-select"
                          placeholder="Select Therapist"
                          required
                        />
 </div>
</div>


     <div className="control-group">
     <label className="control-label">
             Payment Mode<a className="blink">*</a>
           </label>
           <div className="controls">
           <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="payment_mode"
                      options={payment_modeoption}
                      value={payment_modeoption.find(option => option.value === data.payment_mode)}
                      onChange={handlepayment_modeChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Payment mode"
                    />
           </div>
         </div>
         <div className="control-group">
         <label className="control-label">
             Basic Amount<a className="blink">*</a>
           </label>
             <input type="number" id="basic_amount"
                className="span6  popovers"
              name="basic_amount" required  value={data.basic_amount} onChange={handlebasic_amount}/>
         </div>
         <div className="control-group">
         <label className="control-label">
             Tax Amount<a className="blink">*</a></label>
           <div className="controls">
             <input
               type="number"
               name="tax_amount"
               id="tax_amount"
               readOnly
               value={data.tax_amount}
                className="span7  popovers"
               disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Total Bill Amount<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="number"
                className="span7  popovers"
               name="total_bill_amount"
               id="total_bill_amount"
               readOnly
               disabled
               value={data.total_bill_amount}
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Paid Amount<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="text"
                className="span6  popovers"
               name="paid_amount"
               id="paid_amount"
               value={data.paid_amount}
               onChange={handlepaid_amount_change}
              />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Amount in Words<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="text"
               name="word"
               id="word"
               readOnly
               value={data.amount_in_word}
                className="span7  popovers"
               disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Balance Amount<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="number"
               name="balance_amount"
               id="balance_amount"
               value={data.balance_account}
                className="span7  popovers"
               disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Discount Amount<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="number"
               name="discount_amount"
               id="discount_amount"
               value={data.discount_account}
               onChange={handlediscount}
                className="span6  popovers"

             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Basic Amount After Discount<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="number"
               name="discount_basic_amount"
               id="discount_basic_amount"
               data-trigger="hover"
               readOnly
               value={data.basic_amount_after_discount}
                className="span7  popovers"
               disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             After Discount Tax<a className="blink">*</a>
           </label>
           <div className="controls">
             <input
               type="number"
               id="after_discount_tax"
               value={data.after_discount_tax}
                className="span7  popovers"
               disabled
             />
           </div>
         </div>
         <div className="control-group">
         <label className="control-label">
             Total Amount
             <a className="blink" >
             </a>
           </label>
           <div className="controls">
             <input
               type="number"
               name="total_amount"
               id="total_amount"
            value={data.total_amount}
             className="span7  popovers"
            disabled
             />
           </div>
         </div>

         <div className="control-group">
         <label className="control-label">
             Total Services Hour
             <a className="blink">
             </a>
           </label>
           <div className="controls">
           <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="total_service_hour"
                      options={treactmentoption}
                      value={treactmentoption.find(option => option.value === data.total_service_hour)}
                      onChange={handletreatmentChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Add Services Hour"
                    />
           </div>
         </div>
         <div className="createcancel_btn">
           <button
           type="submit"
           onClick={handlesubmit}
           className="btn-data"
           disabled={submitting}
           >
             {submitting ? 'Submitting...' : 'Renew'}
           </button>
           <button type="reset" onClick={handleclear} className="cancel-btn btn">Cancel</button>
         </div>
         </div>
       </form>)}
   </div>
   </div> }
</div>
);
};

export default Renew