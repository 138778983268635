import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import DatePicker from "react-datepicker";
import { default as Select } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptData, encryptData } from '../cryptoutils/cryptoutils';

const Tipadmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate=useNavigate()
    const{userid,memberid,tipaccountid}=useParams()
    const userId=decryptData(userid)
    const memberId=decryptData(memberid)
    const tipmasterid=decryptData(tipaccountid)

  const [show, setshow] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [payment, setpayment] = useState([]);
  const[data,setData]=useState([])
  const [therapistName, setTherapistName] = useState([]);
  const[branchname,setbranchname]=useState([])
  console.log(userid,"tip")
  const [formValues, setFormValues] = useState({
    paymentmode: "",
    therapist_name: '',
    total_tip_amount: '',
    branchnamedata:"",
    receivedate:"",
    adddate:"",
    tip_amount_80:'',
    tip_amount_20:'',
    tistatus:""
  });


  const handlecancel=()=>{
    setFormValues({
      paymentmode: "",
    therapist_name: '',
    total_tip_amount: '',
    branchnamedata:"",
    receivedate:"",
    adddate:"",
    tip_amount_80:'',
    tip_amount_20:'',
    tistatus:""
    })
  }


  const handlepaid_amount_change = (e) => {
    console.log(e.target.value)
    const paidData = e.target.value;
    if (!isNaN(paidData)) {
      const paidValue = parseFloat(paidData);

      setFormValues((prev)=>({
        ...prev,
        total_tip_amount:paidValue,
        tip_amount_80:paidValue*0.7 || 0,
        tip_amount_20:paidValue*0.3 || 0
      }))

    }

    if (isNaN(paidData)) {
      setFormValues((prev) => ({
        ...prev,
        total_tip_amount:"",
        tip_amount_80:"",
        tip_amount_20:""
      }));
   } };
  const querydata=async()=>{
    let obj = {
      query: `[dbo].[spasofware_web_proc_tip_account_subadmin_getdata]@tipid='{0}'`,
      queryArr: [`${tipmasterid}`]
    }
      const getdata= await  Hexapi(obj)
      console.log(getdata,"--------------");
      setData(getdata[""]);
      setpayment(getdata.paymentmode   || []);
      setTherapistName(getdata.therapistname || []);
      setbranchname(getdata.branch || [])
      setDataAvailable(true)
      const dataprev=getdata[""][0]
    setTimeout(() => {
      setFormValues({
        paymentmode: dataprev.payment_mode,
        therapist_name: dataprev.therapist_name,
        total_tip_amount: dataprev.total_tip_amount,
        branchnamedata:dataprev.branch_name,
        receivedate:dataprev.service_date.date.split(' ')[0]        ,
        adddate:dataprev.add_date.date.split(' ')[0],
        tip_amount_80:dataprev.tip_amount_80,
        tip_amount_20:dataprev.tip_amount_20,
        tistatus:dataprev.tip_status === 1 ? "Confirm" : "Cancel"
      })
    }, 500);

  }
  useEffect(() => {
    querydata()
  }, []);


  const handleupdatequery=async()=>{
    const obj = {
      query : `[dbo].[spa_software_web_proc_tip_edit_admin]@tipmasterid='{0}',@tip_status='{1}',@payment_mode='{2}',@therapist_name='{3}',@total_tip_amount='{4}',@eighty_tip_amount='{5}',@twenty_tip_amount='{6}',@branch_name='{7}',@receive_date='{8}',@add_date='{9}'`,
      queryArr:[`${tipmasterid}`,`${formValues.tistatus}`,`${formValues.paymentmode}`,`${formValues.therapist_name}`,`${formValues.total_tip_amount}`,`${formValues.tip_amount_80}`,`${formValues.tip_amount_20}`,`${formValues.branchnamedata}`,`${formValues.receivedate}`,`${formValues.adddate}`]
    }
    const resp= await Hexapi(obj)
    console.log(resp,"tip")
 navigate(`/tipaccountadmin/${ encodeURIComponent(encryptData(userId))}/${ encodeURIComponent(encryptData(memberId))}`)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(e.target.value);
  };

  const therapistOptions = therapistName.map(therapist => ({
    value: therapist.therapist_name,
    label: therapist.therapist_name
  }));

  // therapistOptions.push({
  //   label:managername,
  //   value:managername
  // })

  const handleTherapistChange = (selectedOption) => {
    setFormValues({ ...formValues, therapist_name: selectedOption ? selectedOption.value : '' });
  };

  const handlebranchChange = (selectedOption) => {
    setFormValues({  ...formValues,  branchnamedata: selectedOption ? selectedOption.value : '' });
  };
  const branchOptions = branchname.map(br => ({
    value: br.branchname,
    label: br.branchname
  }))

  const paymentOptions = payment.map(th => ({
    value: th.paymentmode,
    label: th.paymentmode
  }));
    const handlepaymentChange = (selectedOption) => {
      setFormValues({ ...formValues, paymentmode: selectedOption ? selectedOption.value : '' });
    };


    const statusoptions = [{
      value:1,
      label:"Confirm"
    },{ value:0,
      label:"Cancel"
    }]
      const handletipChange = (selectedOption) => {
        console.log(selectedOption,"--------")
        setFormValues({ ...formValues, tistatus: selectedOption ? selectedOption.value : '' });
      };


    const handleChangeReport = (date, name) => {
      setFormValues({ ...formValues, [name]: date.toISOString().split('T')[0] });
    };
    const setCurrentDateIfEmpty = (name) => {
      if (!data[name]) {
        setFormValues({ ...formValues, [name]: new Date().toISOString().split('T')[0] });
      }
    };

  return (
      <div className="container top-acc highlightBcg">
      <div className="row">
        <div className="dashservice">
          <p className="name-text">
            Tip Accounts
          </p>

            <FaChevronDown onClick={() => { setshow(!show) }} className="hideShowBtn" />

        </div>
      </div>

      <div className="row bg-body-tertiary
      ">
        {show && (
          <div className="form-data" >
            <div className="controls">
            <div className="control-group">
                <label className="control-label">
                  Select Branch Name
                </label>
                <div className="blinkTextDiv controls">
                <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="branch"
                      options={branchOptions}
                      value={branchOptions.find(option => option.value === formValues.branchnamedata)}
                      onChange={handlebranchChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Branch Name"
                    />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                Receive Tip Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={formValues.receivedate}
                    onChange={(date) => handleChangeReport(date, 'receivedate')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('receivedate')}
                  />


                </div>
              </div>
              <div className="control-group">
                <label className="control-label">

                Add Tip Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={formValues.adddate}
                    onChange={(date) => handleChangeReport(date, 'adddate')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('adddate')}
                  />


                </div>
              </div>

              <div className="control-group">
                <label className="control-label">
                  Select Therapist Name
                </label>
                <div className="blinkTextDiv controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapist_name"
                    options={therapistOptions}
                    value={therapistOptions.find(option => option.value === formValues.therapist_name)}
                    onChange={handleTherapistChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Therapist"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Select Payment Mode
                </label>
                <div className="blinkTextDiv controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="therapistnamee"
                    options={paymentOptions}
                    value={paymentOptions.find(option => option.value ===formValues.paymentmode)}
                    onChange={handlepaymentChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Payment Mode"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Total Tip Amount
                </label>
                <div className="controls">
                  <input
                    type="text"
                    className="span6  popovers"
                    name="customer_name"
                    id="customer_name"
                    required
                    value={formValues.total_tip_amount}

onChange={handlepaid_amount_change}
                  />
                </div>
              </div>


              <div className="control-group">
                <label className="control-label">
                  70% Tip Amount
                </label>
                <div className="controls">
                  <input
                    className="span7  popovers"
                    type="text"
 value={formValues.tip_amount_80}
                    name="customer_no"
                    id="customer_no"
                    required

                    disabled

                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  30% Tip Amount
                </label>
                <div className="controls">
                  <input
                    className="span7  popovers"
                    type="text"
                    disabled
                  value={formValues.tip_amount_20}
                    name="customer_no"
                    id="customer_no"
                    required
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Tip Status
                </label>
                <div className="blinkTextDiv controls">
                <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="tistatus"
                    options={statusoptions}
                    value={statusoptions.find(option => option.value ===formValues.tistatus)}
                    onChange={handletipChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select status"
                    required
                  />
                </div>
              </div>
              <div className="createcancel_btn">
                <button
                  // type="submit"
                  className="btn-data"
                onClick={handleupdatequery}
                >
                  Update
                </button>
                <button type="reset" className="cancel-btn btn" onClick={handlecancel}>Cancel</button>
              </div>
            </div>
          </div>)}
      </div>

    </div>
  );
};

export default Tipadmin