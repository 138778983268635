import React, { useEffect, useState ,useMemo} from "react";
import "./QuickSearch.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FaChevronDown} from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { lineSpinner } from 'ldrs'
import Walikncashsale from "./Walikncashsale";
import { Spinner, Table } from "react-bootstrap";
import Walkincard from "./Walkincard";
import { default as Select } from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Walkinpaytm from "./Walkinpaytm";
import Walkingrouponsale from "./Walkingrouponsale";
import Membershipcashsale from "./Membershipcashsale";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import Walkinmakemytrip from "./Walkinmakemytrip";
import Membershipcardsale from "./Membershipcardsale";
import Membershippaytmsale from "./Membershippaytmsale";
import Tipcardsale from "./Tipcardsale";
import Tippaytm from "./Tippaytm";
import Pendingpaytm from "./Pendingpaytm";
import Pendingcard from "./Pendingcard";
import Pendingcash from "./Pendingcash";
lineSpinner.register()

const Salereport=()=>{
   const [isFormVisible, setFormVisible] = useState(true);
  const [data,setdata]=useState({
    branch_name:"",
    startdate:"",
    enddate:""
  })
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const[showtable,setshowtable]=useState(true)
  const[loading,setLoading]=useState(false)
  const[branchnamedata,setbranchnamedata]=useState([])
  const[report,setreport]=useState([])
  const[show,setshow]=useState(false)
  const[walkincard,setwalkincard]=useState(false)
  const[walkinpytm,setwalkinpaytm]=useState(false)
  const[datedata,setdatedata]=useState("")
  const[branch,setbranch]=useState("")
  const[walkingroup,setwalkingroup]=useState(false)
  const[walkinmake,setwalkinmake]=useState(false)
  const[membershipcashsale,setmembershipcashsale]=useState(false)
  const[membershipcardsale,setmembershipcardsale]=useState(false)
  const[membershippaytmsale,setmembershippaytmsale]=useState(false)
  const[tipcard,settipcard]=useState(false)
  const[tippaytm,settipaytm]=useState(false)
 const[pendingcash,setpendingcash]=useState(false)
 const[pendingcard,setpendingcard]=useState(false)
 const[pendingpaytm,setpendingpaytm]=useState(false)
 const [itemsPerPage, setItemsPerPage] = useState(10);


  const handlewalkin=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setshow(true)
  }
  const handlewalkincard=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setwalkincard(true)
  }
  const handlewalkinpaytm=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setwalkinpaytm(true)
  }
  const handlewgroupon=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setwalkingroup(true)
  }
  const handlewmaketrip=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setwalkinmake(true)
  }
  const handlemembershipcashsale=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setmembershipcashsale(true)
  }
  const handlemembershipcardsale=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setmembershipcardsale(true)
  }
  const handlemembershippaytmsale=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setmembershippaytmsale(true)
  }
  const handletipcardsale=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setmembershippaytmsale(true)
  }
  const handletippaytmsale=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setmembershippaytmsale(true)
  }
  const handlependingcash=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setpendingcash(true)
  }
  const handlependingcard=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
  setpendingcard(true)
  }
  const handlependingpaytm=(date,branchn)=>{
  // setbranch(branchn)
  setdatedata(date)
setpendingpaytm(true)
  }

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handlebranchnameget=async()=>{
    const obj = {
      query: `[dbo].[spa_software_web_proc_daily_Sale_report_branchname]`,
      queryArr:[]
    }
    const getdata = await Hexapi(obj);
    setbranchnamedata(getdata.branch || [])
  }

  useEffect(() => {
handlebranchnameget()
  }, []);


      const handlereport=async(e)=>{
        e.preventDefault()
        setLoading(true)
        const obj={
          query:`[dbo].[spa_software_web_proc_daily_Sale_report_format2]@branchname='{0}',@startdate='{1}',@enddate='{2}'`,
          queryArr:[`${data.branch_name}`,`${data.startdate}`,`${data.enddate}`]
        }
        const getdata= await Hexapi(obj)

        console.log(getdata,"report")

       setbranch(data.branch_name)
       setreport(getdata[""])
       setLoading(false)
      }


      const handleChangeReport = (date, name) => {
        if (date) {
          setdata({ ...data, [name]: date.toISOString().split('T')[0] });
        } else {
          setdata({ ...data, [name]: "" });
        }
      };

      const setCurrentDateIfEmpty = (name) => {
        if (!data[name]) {
          setdata({ ...data, [name]: new Date().toISOString().split('T')[0] });
        }
      };

  const handlebranchChange = (selectedOption) => {
    setdata({ ...data, branch_name: selectedOption ? selectedOption.value : '' });
  };
  const branchOptions = branchnamedata.map(br => ({
    value: br.branchname,
    label: br.branchname
  }))

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const formattedData = report.map((item,i) => ({
      'sr no': i+1,
      'Date': item.date,

        "Walkin Cash Sale" : `${item.walkincashsale !==0 ? item.walkincashsale : ""}`,
        "Walkin Cash Back Sale":`${item.walkincashbacksale !==0 ? item.walkincashbacksale : ""}`,
        "Walkin Card Sale" : `${item.walkincashsale !==0 ? item.walkincashsale : ""}`,
        "Walkin Paytm Sale" : `${item.walkingpaytmsale !==0 ? item.walkingpaytmsale : ""}`,
        "Walkin Groupon Sale" : `${item.walkinggrouponsale !==0 ? item.walkinggrouponsale : ""}`,
        "Walkin Make My trip Sale" : `${item.walkingcardmakemytripsale !==0 ? item.walkingcardmakemytripsale : ""}`,
        "Membership Cash Sale" : `${item.membershipcashsale !==0 ? item.membershipcashsale: ""}`,
        "Membership Card Sale" : `${item.membershipcardsale !==0 ? item.membershipcardsale : ""}`,
        "Membership Paytm Sale"  : `${item.membershippaytmsale !==0 ? item.membershippaytmsale : ""}`,
        "Tip 100% Card Sale" : `${item.total_tip_amountcard !==0 ? item.total_tip_amountcard : ""}`,
        "Tip 100% Paytm Sale" : `${item.totaltip_paytm !==0 ? item.totaltip_paytm : ""}`,
        "Paid Pending Cash" : `${item.pending_amount_cashpaid!==0 ? item.pending_amount_cashpaid : ""}`,
         "Paid Pending Card" : `${item.pending_amount_cardpaid !==0 ? item.pending_amount_cardpaid : ""}`,
         "Paid Pending Paytm" : `${item.pending_amount_paytmpaid !==0 ? item.pending_amount_paytmpaid : ""}`,
         "Total Cash In-Hand" : `${item.total_cash_sale !==0 ? item.total_cash_sale : ""}`,
         "Total Cash In-Bank" : `${item.total_bank_record !==0 ? item.total_bank_record : ""}`,
         "Total Sale" : `${item.net_sale !==0 ? item.net_sale : null}`

    }))
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Sale_Report' + fileExtension;
    saveAs(data, fileName);
  };
  const columns = useMemo(
    () => [
      { Header: "Date", accessor: "date" },
      { Header: "Walking Cash Sale", accessor: "walkincashsale",Cell: ({row})=>( <>{row.original.walkincashsale !==0 ? <p onClick={()=>{handlewalkin(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.walkincashsale}</p> : null}</>) },
      { Header: "Walking Cash Back Sale", accessor: "walkincashbacksale",Cell:({row})=>(
        <p><b>{row.original.walkincashbacksale===0 ? null : row.original.walkincashbacksale}</b></p>) },
      { Header: "Walking Card Sale", accessor: "walkingcardsale" ,Cell: ({row})=>( <>{row.original.walkingcardsale !==0 ? <p onClick={()=>{handlewalkincard(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.walkingcardsale}</p> : null}</>)},
      { Header: "Walking Paytm Sale", accessor: "walkingpaytmsale",Cell: ({row})=>( <>{row.original.walkingpaytmsale !==0 ? <p onClick={()=>{handlewalkinpaytm(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.walkingpaytmsale}</p> : null}</>) },
      { Header: "Walking Groupon Sale", accessor: "walkinggrouponsale",Cell: ({row})=>( <>{row.original.walkinggrouponsale !==0 ? <p onClick={()=>{handlewgroupon(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.walkinggrouponsale}</p> : null}</>) },
      { Header: "Walking Make My Trip Sale", accessor: "walkingcardmakemytripsale",Cell: ({row})=>( <>{row.original.walkingcardmakemytripsale !==0 ? <p onClick={()=>{handlewmaketrip(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.walkingcardmakemytripsale}</p> : null}</>) },
      { Header: "Member -ship Cash Sale", accessor: "membershipcashsale",Cell: ({row})=>( <>{row.original.membershipcashsale !==0 ? <p onClick={()=>{handlemembershipcashsale(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.membershipcashsale}</p> : null}</>) },
      { Header: "Member -ship Card Sale", accessor: "membershipcardsale" ,Cell: ({row})=>( <>{row.original.membershipcardsale !==0 ? <p onClick={()=>{handlemembershipcardsale(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.membershipcardsale}</p> : null}</>)},
      { Header: "Member -ship Paytm Sale", accessor: "membershippaytmsale",Cell: ({row})=>( <>{row.original.membershippaytmsale !==0 ? <p onClick={()=>{handlemembershippaytmsale(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.membershippaytmsale}</p> : null}</>) },
      { Header: "Tip 100% Card Sale", accessor: "total_tip_amountcard",Cell: ({row})=>( <>{row.original.total_tip_amountcard !==0 ? <p onClick={()=>{handletipcardsale(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.total_tip_amountcard}</p> : null}</>) },
      { Header: "Tip 100% Paytm Sale", accessor: "totaltip_paytm" ,Cell: ({row})=>( <>{row.original.totaltip_paytm !==0 ? <p onClick={()=>{handletippaytmsale(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.totaltip_paytm}</p> : null}</>)},
      { Header: "Rced Pending Cash", accessor: "pending_amount_cashpaid",Cell: ({row})=>( <>{row.original.pending_amount_cashpaid !==0 ? <p onClick={()=>{handlependingcash(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.pending_amount_cashpaid}</p> : null}</>) },
      { Header: "Rced Pending Card", accessor: "pending_amount_cardpaid",Cell: ({row})=>( <>{row.original.pending_amount_cardpaid !==0 ? <p onClick={()=>{handlependingcard(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.pending_amount_cardpaid}</p> : null}</>) },
      { Header: "Rced Pending Paytm", accessor: "pending_amount_paytmpaid",Cell: ({row})=>( <>{row.original.pending_amount_paytmpaid !==0 ? <p onClick={()=>{handlependingpaytm(row.original.date,data.branch_name)}} className="text-data curpoint">{row.original.pending_amount_paytmpaid}</p> : null}</>) },
      { Header: "Total Cash Back", accessor: "walkincashbacksale2",Cell:({row})=>(
          <p><b style={{ color: "#000" }}>{row.original.walkincashbacksale2===0 ? null : row.original.walkincashbacksale}</b></p>)},

      { Header: "Total Cash In-Hand", accessor: "total_cash_sale" ,Cell:({row})=>(
        <p><b style={{ color: "#CC0000" }}>{row.original.total_cash_sale===0 ? null : row.original.total_cash_sale}</b></p>)
      },
      { Header: "Total Cash In-Bank", accessor: "total_bank_record" ,Cell:({row})=>(
        <p><b style={{ color: "#0000FF" }}>{row.original.total_bank_record===0 ? null : row.original.total_bank_record}</b></p>)},
      { Header: "Total Sale", accessor: "net_sale",Cell:({row})=>(
        <p><b style={{ color:  "#006600" }}>{row.original.net_sale===0 ? null : row.original.net_sale}</b></p>) },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report|| [],
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // custdate.map((date) => report[date][0])

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);



  return (
    <>
{ !show && !walkincard && !walkinpytm && !walkingroup && !walkinmake && !membershipcashsale && !membershipcardsale && !membershippaytmsale && !tipcard && !tippaytm && !pendingcard && !pendingcash && !pendingpaytm?
 (<>
        <div className="quick_search_page">
      <div className="widget-title">
        <p className="name-text">
          Search Report
        </p>
        <span className="">

            <FaChevronDown  onClick={toggleFormVisibility}/>

        </span>
      </div>
      </div>
      {isFormVisible && (
  <form className="widget-body form" onSubmit={handlereport}>
    <div className="control-group-div">
      <div className="control-group">
        <label className="control-label">Select SPA Branch</label>
        <div className="controls">
          <Select
            className="span6"
            id="therapist_weekly_off"
            name="branch_name"
            options={branchOptions}
            value={branchOptions.find(option => option.value === data.branch_name)}
            onChange={handlebranchChange}
            isClearable
            classNamePrefix="react-select"
            placeholder="Select Branch Name"
          />
        </div>
      </div>
      <div className="control-group">
        <label className="control-label">Select Start Date</label>
        <div className="controls">
          <DatePicker
            selected={data.startdate}
            onChange={(date) => handleChangeReport(date, 'startdate')}
            className="span6 popovers"
            dateFormat="dd/MM/yyyy"
            onFocus={() => setCurrentDateIfEmpty('startdate')}
          />
        </div>
      </div>
      <div className="control-group">
        <label className="control-label">Select End Date</label>
        <div className="controls">
          <DatePicker
            selected={data.enddate}
            onChange={(date) => handleChangeReport(date, 'enddate')}
            className="span6 popovers"
            dateFormat="dd/MM/yyyy"
            onFocus={() => setCurrentDateIfEmpty('enddate')}
          />
        </div>
      </div>

      <div className="form-actions-control">
        <button
          type="submit"
          name="ShowInfoBtn"
          className="btn btn-success"
          id="show_btn"
        >
          Show Report
        </button>
      </div>
      <br />
      <br />
    </div>
  </form>
)}




<div className=" container mb-5">
<div className="row bg-body-tertiary mt-4">
<div className="dashservice">
  <p className="name-text"> Sale Report &nbsp;: -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span className="text-danger">Branch : {data.branch_name}</span></p>
  <FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
 <div>
 {showtable && (
 <div>

 <div className="d-flex justify-content-between mt-2">
    <select
             value={itemsPerPage}
             onChange={(e) => {
               setItemsPerPage(Number(e.target.value));
               setPageSize(Number(e.target.value));
             }}
           >
             {[10, 25, 50, 75, 100].map((size) => (
               <option key={size} value={size}>
                 Show {size}
               </option>
             ))}
           </select>

     <input
       type="text"
       value={globalFilter || ""}
       onChange={e => setGlobalFilter(e.target.value)}
       placeholder="Search..."
     />


           </div>
     <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>

       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render("Header")}
                 <span>
                   {column.isSorted
                     ? column.isSortedDesc
                       ? " 🔽"
                       : " 🔼"
                     : ""}
                 </span>
               </th>
             ))}
           </tr>
         ))}
       </thead>

       <tbody {...getTableBodyProps()}>
         {loading ? (
           <tr>
           <td colSpan={19} className="text-center">
           <Spinner animation="border" />
         </td>
            </tr>
         ) : report.length === 0 ? (
           <td className=" fs-6" colSpan={15}>No Data Available</td>
         ) : (
           page.map(row => {
             prepareRow(row);
             return (
               <tr {...row.getRowProps()}>
                 {row.cells.map(cell => (
                   <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                 ))}
               </tr>
             );
           })
         )}
       </tbody>
     </Table>

  <div className="d-flex justify-content-between mb-3">
     <span>
               Showing {startIndex}-{endIndex} of {totalEntries} entries
               {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
             </span>
     <div className="pagination">
       <button onClick={() => previousPage()} disabled={!canPreviousPage}>
         Previous
       </button>
       <span>
         Page{' '}
         <strong>
           {pageIndex + 1} of {pageOptions.length}
         </strong>{' '}
       </span>
       <button onClick={() => nextPage()} disabled={!canNextPage}>
         Next
       </button>
     </div>
     </div>
     <button onClick={exportToExcel} className="btn-click-6">
          Generate Report
        </button>

   </div>
  )}
 </div>
      </div>
</div></>) : null}

{ show && <Walikncashsale custdatedata={datedata} branchname={branch} setback={()=>{setshow(false)}}/>}
{walkincard && <Walkincard custdatedata={datedata} branchname={branch} setback={()=>{setwalkincard(false)}}/>}
{ walkinpytm && <Walkinpaytm custdatedata={datedata} branchname={branch} setback={()=>{setwalkinpaytm(false)}}/>}
  { walkingroup && <Walkingrouponsale custdatedata={datedata} branchname={branch} setback={()=>{setwalkinpaytm(false)}} />}
  { walkinmake && <Walkinmakemytrip custdatedata={datedata} branchname={branch} setback={()=>{setwalkinmake(false)}} />}
  { membershipcashsale && <Membershipcashsale custdatedata={datedata} branchname={branch} setback={()=>{setmembershipcashsale(false)}} />}
  { membershipcardsale && <Membershipcardsale custdatedata={datedata} branchname={branch} setback={()=>{setmembershipcardsale(false)}} />}
  { membershippaytmsale && <Membershippaytmsale custdatedata={datedata} branchname={branch} setback={()=>{setmembershippaytmsale(false)}} />}
  {tipcard && <Tipcardsale custdatedata={datedata} branchname={branch} setback={()=>{settipcard(false)}} />}
  {tippaytm && <Tippaytm custdatedata={datedata} branchname={branch} setback={()=>{settipaytm(false)}} />}
  {pendingcash && <Pendingcash custdatedata={datedata} branchname={branch} setback={()=>{setpendingcash(false)}} />}
  {pendingcard && <Pendingcard custdatedata={datedata} branchname={branch} setback={()=>{setpendingcard(false)}} />}
  {pendingpaytm && <Pendingpaytm custdatedata={datedata} branchname={branch} setback={()=>{setpendingpaytm(false)}} />}
    </>
  );
}

export default Salereport



















// if (!data.branch_name) {
//   alert('Please select a branch');
//   return;
// }
// if (!data.startdate) {
//   alert('Please select a start date');
//   return;
// }
// if (!data.enddate) {
//   alert('Please select an end date');
//   return;
// }















 // <td style={{ width: "200px" }}>{ele.cust_date}</td>
  // <td onClick={()=>{handlewalkin(ele.cust_date,data.branch_name)}} className="text-data curpoint">{querydata[ele.cust_date][0]?.walkincashsale ===0 ? null : querydata[ele.cust_date][0]?.walkincashsale}</td>
  // <td >{querydata[ele.cust_date][0]?.walkincashbacksale ===0 ? null : querydata[ele.cust_date][0]?.walkincashbacksale}</td>
  // <td onClick={()=>{handlewalkincard(ele.cust_date,data.branch_name)}} className="text-data curpoint">{querydata[ele.cust_date][0]?.walkingcardsale===0 ? null : querydata[ele.cust_date][0]?.walkingcardsale}</td>
  // <td onClick={()=>{handlewalkinpaytm(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.walkingpaytmsale ===0 ? null : querydata[ele.cust_date][0]?.walkingpaytmsale}</td>
  // <td onClick={()=>{handlewgroupon(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.walkinggrouponsale ===0 ? null : querydata[ele.cust_date][0]?.walkinggrouponsale }</td>
  // <td  onClick={()=>{handlewmaketrip(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.walkingcardmakemytripsale ===0 ? null : querydata[ele.cust_date][0]?.walkingcardmakemytripsale }</td>
  // <td  onClick={()=>{handlemembershipcashsale(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.membershipcashsale ===0 ? null : querydata[ele.cust_date][0]?.membershipcashsale}</td>
  // <td  onClick={()=>{handlemembershipcardsale(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.membershipcardsale ===0 ? null :querydata[ele.cust_date][0]?.membershipcardsale }</td>
  // <td onClick={()=>{handlemembershippaytmsale(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.membershippaytmsale ===0 ? null : querydata[ele.cust_date][0]?.membershippaytmsale ===0}</td>
  // <td onClick={()=>{handletipcardsale(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.total_tip_amountcard ===0 ? null : querydata[ele.cust_date][0]?.total_tip_amountcard}</td>
  // <td onClick={()=>{handletippaytmsale(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.totaltip_paytm === 0 ? null : querydata[ele.cust_date][0]?.totaltip_paytm}</td>
  // <td onClick={()=>{handlependingcash(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.pending_amount_cashpaid === 0 ? null : querydata[ele.cust_date][0]?.pending_amount_cashpaid}</td>
  // <td onClick={()=>{handlependingcard(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.pending_amount_cardpaid ===0 ? null : querydata[ele.cust_date][0]?.pending_amount_cardpaid }</td>
  // <td onClick={()=>{handlependingpaytm(ele.cust_date,data.branch_name)}} className=" text-data curpoint">{querydata[ele.cust_date][0]?.pending_amount_paytmpaid ===0 ? null : querydata[ele.cust_date][0]?.pending_amount_paytmpaid}</td>
  // <td><b style={{ color: "#000" }}>{querydata[ele.cust_date][0]?.walkincashbacksale===0 ? null : querydata[ele.cust_date][0]?.walkincashbacksale}</b></td>
  // <td><b style={{ color: "#CC0000" }}>{querydata[ele.cust_date][0]?.total_cash_sale===0 ? null : querydata[ele.cust_date][0]?.total_cash_sale}</b></td>
  // <td><b style={{ color: "#0000FF" }}>{querydata[ele.cust_date][0]?.total_bank_record===0 ? null : querydata[ele.cust_date][0]?.total_bank_record}</b></td>
  // <td><b style={{ color:  "#006600"  }}>{querydata[ele.cust_date][0]?.net_sale ===0 ? null : querydata[ele.cust_date][0]?.net_sale}</b></td>